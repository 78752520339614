import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { H1 } from 'Atoms/text';
import { AZSectionType, useSections } from 'pages/AZ/AZPageCategories';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { isLoading } from 'types/common';

const TitleContainer = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
`;

const Title = styled(H1)`
  margin: 40px 0 0 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 20px 0 0 0;
  }
`;

const SubTitle = styled(Link)`
  margin: 40px 0 50px 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 20px 0 20px 0;
  }
`;

export const AZPageTitle: FC = () => {
  const sections = useSections();

  if (isLoading(sections)) {
    return <Loader />;
  }

  return (
    <TitleContainer>
      <Title size="normal" weight="500">
        <Switch>
          <Route exact path="/az" render={() => `GIDEON A-Z`} />
          {sections.map((section: AZSectionType) => (
            <Route
              path={`/az/${section.slug}`}
              key={section.slug}
              Redirect
              render={() => `GIDEON ${section.name} A-Z`}
            ></Route>
          ))}
          <Route render={() => 'AZ list'} />
        </Switch>
      </Title>
      <SubTitle size="big" to="/updates">
        What&apos;s New
      </SubTitle>
    </TitleContainer>
  );
};
