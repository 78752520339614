import { getMicrobeSynonyms } from 'apiServices/Microbes/microbes';
import { ReactComponent as Bacteria } from 'assets/Search/Bacteria.svg';
import { ReactComponent as Fungi } from 'assets/Search/Fungi.svg';
import { ReactComponent as Mycobacteria } from 'assets/Search/Mycobacteria.svg';
import { ReactComponent as Parasite } from 'assets/Search/parasitic.svg';
import { ReactComponent as Virus } from 'assets/Search/Virus.svg';
import { ReactComponent as Yeasts } from 'assets/Search/Yeasts.svg';
import { ReactComponent as All } from 'assets/UI/All.svg';
import { ExploreBackground } from 'Atoms/explore/ExploreBackground';
import { ExploreLandingTitles } from 'Atoms/explore/ExploreLandingTitles';
import { Icon, SvgComponent } from 'Atoms/Icon';
import { Label } from 'Atoms/Label';
import { Span } from 'Atoms/text';
import { TrendingNow } from 'Explore/Organisms/TrendingNow';
import { MainLayout } from 'layouts/MainLayout';
import { Disclaimer } from 'Molecules/Disclaimer';
import { ExploreSelectWithFilter } from 'Organisms/ExploreSelectWithFilter';
import React, { FC, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { OnChangeValue } from 'react-select';
import { useMobile } from 'services/useMobile';
import { useSelectSynonyms } from 'services/useSelectSynonyms.hook';
import { useMicrobesResource } from 'store/microbesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { MicrobeFilter } from 'types/microbeDetails';
import { SelectOption } from 'types/select';
import { SimpleMicrobe } from 'types/simpleMicrobe';

const MainLayoutStyled = styled(MainLayout)`
  margin-top: 20px;

  display: flex;
  flex-direction: column;
`;

const StyledDisclaimer = styled(Disclaimer)`
  max-width: 735px;
  margin: 20px auto 0 auto;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: 80%;
  }

  ${Icon} {
    width: 45px;
    height: 45px;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      width: 35px;
      height: 35px;
    }
  }
`;

const LabelStyled = styled(Label)`
  margin: 30px auto 0 auto;
`;

const mapToExploreOptions = (data: SimpleMicrobe[], isSynonym = false): SelectOption[] => {
  return data.map(x => ({
    value: `${x.type}/${x.id.toString()}`,
    label: x.name,
    isSynonym: isSynonym,
  }));
};

type FilterSelectOption = {
  value: MicrobeFilter;
  label: string;
  Icon: SvgComponent;
};

const filterSelectOptions: FilterSelectOption[] = [
  {
    value: 'all',
    label: 'All',
    Icon: All,
  },
  {
    value: 'bacteria',
    label: 'Bacteria',
    Icon: Bacteria,
  },
  {
    value: 'mycobacteria',
    label: 'Mycobacteria',
    Icon: Mycobacteria,
  },
  {
    value: 'viruses',
    label: 'Viruses',
    Icon: Virus,
  },
  {
    value: 'fungi',
    label: 'Fungi',
    Icon: Fungi,
  },
  {
    value: 'parasites',
    label: 'Parasites',
    Icon: Parasite,
  },
  {
    value: 'yeasts',
    label: 'Yeasts and Algae',
    Icon: Yeasts,
  },
];

export const Microbes: FC = () => {
  const isMobile = useMobile('s');
  const microbesState = useMicrobesResource();
  const [filterValue, setFilterValue] = useState<FilterSelectOption>(filterSelectOptions[0]);
  const [microbes, setMicrobes] = useState<SelectOption[]>();

  assertIsNotStoreError(microbesState);

  const { allMicrobes, bacteria, mycobacteria, virus, yeasts, parasite, fungus } = useMemo((): {
    allMicrobes: SelectOption[];
    bacteria: SelectOption[];
    mycobacteria: SelectOption[];
    virus: SelectOption[];
    parasite: SelectOption[];
    fungus: SelectOption[];
    yeasts: SelectOption[];
  } => {
    if (isLoading(microbesState)) {
      return {
        bacteria: [],
        mycobacteria: [],
        yeasts: [],
        virus: [],
        parasite: [],
        fungus: [],
        allMicrobes: [],
      };
    }

    return {
      bacteria: mapToExploreOptions(microbesState.bacteria),
      mycobacteria: mapToExploreOptions(microbesState.mycobacteria),
      yeasts: mapToExploreOptions(microbesState.yeasts),
      allMicrobes: mapToExploreOptions(microbesState.allMicrobes),
      virus: mapToExploreOptions(microbesState.viruses),
      parasite: mapToExploreOptions(microbesState.parasites),
      fungus: mapToExploreOptions(microbesState.fungi),
    };
  }, [microbesState]);

  const loadSynonymOptions = async (inputValue: string): Promise<SelectOption[]> => {
    const synonyms = await getMicrobeSynonyms(filterValue.value, inputValue);
    const microbeType = filterValue.value === 'all' ? 'allMicrobes' : filterValue.value;
    return !isLoading(microbesState)
      ? synonyms.map(x => ({
          value: `${x.type}/${x.id.toString()}`,
          label: x.name,
          synonymOf: microbesState[microbeType].find(
            microbe => `${microbe.type}/${microbe.id}` === `${x.type}/${x.id}`
          )?.name,
        }))
      : [];
  };

  const { onChange, loadOptions } = useSelectSynonyms(
    'pathogens',
    microbes || allMicrobes,
    loadSynonymOptions
  );

  const handleFilterChange = (value: string): void => {
    const option = filterSelectOptions.find(x => x.value === value);
    option && setFilterValue(option);
    switch (value) {
      case 'bacteria':
        setMicrobes(bacteria);
        break;
      case 'mycobacteria':
        setMicrobes(mycobacteria);
        break;
      case 'yeasts':
        setMicrobes(yeasts);
        break;
      case 'viruses':
        setMicrobes(virus);
        break;
      case 'parasites':
        setMicrobes(parasite);
        break;
      case 'fungi':
        setMicrobes(fungus);
        break;
      default:
        setMicrobes(allMicrobes);
    }
  };

  const onFilterChange = (selected: OnChangeValue<SelectOption, false>): void => {
    if (selected) {
      handleFilterChange(selected.value);
    }
  };

  return (
    <MainLayoutStyled>
      <Helmet>
        <title>Explore microbes - GIDEON</title>
        <meta
          name="description"
          content="GIDEON data on pathogens tracks 2,000+ species of medically important bacteria, mycobacteria, yeasts, and algae. Includes 350+ images of pathogens."
        />
      </Helmet>
      <ExploreBackground imageName="microbes" />
      <ExploreLandingTitles title="Explore GIDEON data on pathogens" />
      <TrendingNow section={filterValue.value === 'all' ? 'bacteria' : filterValue.value} />
      <LabelStyled size="small" color="main" htmlFor="explore-select-input">
        Filter and search across all pathogens records
      </LabelStyled>
      <ExploreSelectWithFilter
        filterOptions={filterSelectOptions}
        onFilterChange={onFilterChange}
        filterValue={filterValue}
        loadOptions={loadOptions}
        onChange={onChange}
        defaultOptions={microbes || allMicrobes}
        placeholder={
          isMobile
            ? 'Search or open the list'
            : "Start typing pathogen's name or pick from the list"
        }
        filterAriaLabel="Microbe type"
        selectInputId="explore-select-input"
      />
      <StyledDisclaimer>
        <Span weight="700">Please note: </Span>Pathogens in this search are grouped according to
        their phenotypic properties and not their biological classification.
      </StyledDisclaimer>
    </MainLayoutStyled>
  );
};
