import { ErrorParams } from 'apiServices/Logs/DTO';
import moment from 'moment-timezone';
import { isStoreError } from 'store/storeError';
import { isLoading, Resource } from 'store/types';
import { AccountSettings } from 'types/accountSettings';
import { CustomError, Errors, ExtraError, isAxiosError } from 'types/errorTypes';


export const prepareErrorReport = (accountSettings: Resource<AccountSettings>, reported:"User Reported"|"Automatically",error?: Errors,): ErrorParams => {


  const errorCode = (): string | number | undefined => {
    if (!error) {
      return;
    }

    if (isAxiosError(error)) {
      return error.response?.status;
    } else if (error instanceof CustomError) {
      return error.code;
    } else if (error && typeof (error as ExtraError).status === 'number') {
      // Cast to ExtraError and check for 'status'
      return (error as ExtraError).status;
    } else {
      return undefined;
    }
  };
  const errorName = (): string | number | undefined => {
    if (!error) {
      return;
    }

    if (isAxiosError(error)) {
      return 'axios error';
    } else if (error instanceof CustomError) {
      return error.type;
    } else if (error && typeof (error as ExtraError).statusText === 'string') {
      // Cast to ExtraError and check for 'status'
      return (error as ExtraError).statusText;
    } else {
      return undefined;
    }
  };

  const reportAPI = (): string | undefined => {
    if (!error) {
      return 'No Error';
    }

    if (isAxiosError(error)) {
      return error.config.url;
    } else if (error instanceof CustomError) {
      return error?.code ? `${error.code}` : 'CustomError';
    } else if (error && typeof (error as ExtraError).config.url) {
      // Cast to ExtraError and check for 'status'
      return (error as ExtraError).config.url;
    } else {
      return undefined;
    }
  };
  const getFallbackValue = (key: keyof AccountSettings, guestValue = 'Guest User'): string => {
    return isLoading(accountSettings) || isStoreError(accountSettings)
      ? guestValue
      : accountSettings[key] || '';
  };

  return {
    datetime: moment().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss zz'),
    errorCode: errorCode() ? `${errorCode()}` : 'Not Found',
    errorName: errorName() ? `${errorName()}` : 'Name Not available',
    api: reportAPI() ? `${reportAPI()}` : 'No API Found',
    url: window.location.href,
    institution_name: getFallbackValue('institution_name'),
    institution: getFallbackValue('institution'),
    firstName: getFallbackValue('firstName'),
    lastName: getFallbackValue('lastName'),
    email: getFallbackValue('email'),
    userAgent: window.navigator.userAgent,
    reported,
  };
};
