import { Loading } from 'store/types';

import { Action, State } from './provider';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'Regions/LoadInitiated': {
      return Loading;
    }
    case 'Regions/Loaded': {
      return {
        data: action.payload,
        list: action.payload.map(val => val.id),
      };
      
    }
    case 'Regions/LoadFailed': {
      return action.payload;
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
