import { Dictionary } from 'types/common';
import { UpdatedField } from 'Updates/types/updatedField';

import {
  isUpdatedBacteriaDTO,
  isUpdatedDiseaseDTO,
  isUpdatedDrugDTO,
  isUpdatedFungiDTO,
  isUpdatedMycobacteriaDTO,
  isUpdatedParasitesDTO,
  isUpdatedVaccineDTO,
  isUpdatedVirusDTO,
  isUpdatedYeastsDTO,
  UpdatedBacteriaDTO,
  UpdatedDiseaseDTO,
  UpdatedDrugDTO,
  UpdatedDTO,
  UpdatedFungiDTO,
  UpdatedMycobacteriaDTO,
  UpdatedParasitesDTO,
  UpdatedVaccineDTO,
  UpdatedVirusDTO,
  UpdatedYeastsDTO,
} from './updates.dto';

const toUpperCase = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

const getCategory = (module: string, subModule: string | undefined): string =>
  subModule ? [toUpperCase(module), toUpperCase(subModule)].join(' - ') : toUpperCase(module);

type GroupedDiseases = Dictionary<UpdatedDiseaseDTO[]>;

const groupById = (data: UpdatedDiseaseDTO[]): GroupedDiseases =>
  data.reduce((acc: GroupedDiseases, value) => {
    const id = value.sub_module
      ? `${value.disease_code}-${value.module}-${value.sub_module}`
      : `${value.disease_code}-${value.module}`;
    if (acc[id]) {
      return {
        ...acc,
        [id]: [...acc[id], value],
      };
    }
    return {
      ...acc,
      [id]: [value],
    };
  }, {});

const mapUpdatedDiseases = (data: UpdatedDiseaseDTO[]): UpdatedField[] => {
  const groupedDiseases = groupById(data);
  return Object.keys(groupedDiseases).map(key => {
    const diseaseFields = groupedDiseases[key];
    const { disease, disease_code, module, sub_module, timestamp } = diseaseFields[0];
    return {
      id: disease_code,
      name: disease,
      category: getCategory(module, sub_module),
      url: `/explore/diseases/${disease_code}`,
      timestamp,
      countries: diseaseFields
        .filter(x => !!x.country_code && !!x.country)
        .map(x => ({
          name: x.country_code === 'G100' ? `Worldwide` : `${x.country}`,
          url: `/explore/diseases/${disease_code}/${
            x.country_code === 'G100' ? 'worldwide' : x.country_code
          }`,
        })),
    };
  });
};

const mapUpdatedDrugs = (data: UpdatedDrugDTO[]): UpdatedField[] =>
  data.map(x => ({
    id: x.drug_code,
    name: x.drug,
    category: getCategory(x.module, x.sub_module),
    url: `/explore/drugs/${x.drug_code}`,
    timestamp: x.timestamp,
  }));

const mapUpdatedVaccines = (data: UpdatedVaccineDTO[]): UpdatedField[] =>
  data.map(x => ({
    id: x.vaccine_code,
    name: x.vaccine,
    category: getCategory(x.module, x.sub_module),
    url: `/explore/vaccines/${x.vaccine_code}`,
    timestamp: x.timestamp,
  }));

const mapUpdatedBacteria = (data: UpdatedBacteriaDTO[]): UpdatedField[] =>
  data.map(x => ({
    id: x.bacteria_code,
    name: x.bacteria,
    category: getCategory(x.module, x.sub_module),
    url: `/explore/pathogens/bacteria/${x.bacteria_code}`,
    timestamp: x.timestamp,
  }));

const mapUpdatedMycobacteria = (data: UpdatedMycobacteriaDTO[]): UpdatedField[] =>
  data.map(x => ({
    id: x.mycobacteria_code,
    name: x.mycobacteria,
    category: getCategory(x.module, x.sub_module),
    url: `/explore/pathogens/mycobacteria/${x.mycobacteria_code}`,
    timestamp: x.timestamp,
  }));

const mapUpdatedYeasts = (data: UpdatedYeastsDTO[]): UpdatedField[] =>
  data.map(x => ({
    id: x.yeast_code,
    name: x.yeast,
    category: getCategory(x.module, x.sub_module),
    url: `/explore/pathogens/yeasts/${x.yeast_code}`,
    timestamp: x.timestamp,
  }));

const mapUpdatedViruses = (data: UpdatedVirusDTO[]): UpdatedField[] =>
  data.map(x => ({
    id: x.virus_code,
    name: x.virus,
    category: getCategory(x.module==='virus'?'viruses':x.module, x.sub_module),     // API is sending x.module='virus' and we need to have it as "viruses"
    url: `/explore/pathogens/viruses/${x.virus_code}`,
    timestamp: x.timestamp,
  }));

const mapUpdatedParasites = (data: UpdatedParasitesDTO[]): UpdatedField[] =>
  data.map(x => ({
    id: x.parasite_code,
    name: x.parasite,
    category: getCategory(x.module, x.sub_module), 
    url: `/explore/pathogens/parasites/${x.parasite_code}`,
    timestamp: x.timestamp,
  }));

const mapUpdatedFungi = (data:UpdatedFungiDTO[]):UpdatedField[]=>
  data.map(x=>({
    id: x.fungus_code,
    name: x.fungus,
    category: getCategory(x.module, x.sub_module),
    url: `/explore/pathogens/fungi/${x.fungus_code}`,
    timestamp: x.timestamp,
  }))


export const mapUpdatedFields = (data: UpdatedDTO[]): UpdatedField[] => {
  return [
    ...mapUpdatedDiseases(data.filter(isUpdatedDiseaseDTO)),
    ...mapUpdatedDrugs(data.filter(isUpdatedDrugDTO)),
    ...mapUpdatedVaccines(data.filter(isUpdatedVaccineDTO)),
    ...mapUpdatedBacteria(data.filter(isUpdatedBacteriaDTO)),
    ...mapUpdatedMycobacteria(data.filter(isUpdatedMycobacteriaDTO)),
    ...mapUpdatedYeasts(data.filter(isUpdatedYeastsDTO)),
    ...mapUpdatedViruses(data.filter(isUpdatedVirusDTO)),
    ...mapUpdatedParasites(data.filter(isUpdatedParasitesDTO)),
    ...mapUpdatedFungi(data.filter(isUpdatedFungiDTO)), 
  ];
};
