import { GraphData, GraphEntity } from 'Atoms/Graph';
import { useMemo } from 'react';
import { VaccinationChartEntity } from 'types/countryDetails';
import { generateArray } from 'utils/generateArray';

interface Point {
  x: string;
  y: number;
}
type Line = {
  title: string;
  points: Point[];
};

type MultiLine = Map<string, (number)[]>;

const getColumnName = (title: string, columns: string[]): string =>
  columns.find(columnName => title.indexOf(columnName) > -1) || title;

const getVaccinationChartLine = (
  vaccinationChartEntity: VaccinationChartEntity,
  columns: string[]
): Line => ({
  title: getColumnName(vaccinationChartEntity.title, columns),
  points: vaccinationChartEntity.coordinates.map<Point>(
    point => ({ x: point.x, y: parseInt(point.y) }),
    {}
  ),
});

const groupLines = (lines: Line[]): MultiLine => {
  const numberOfLines = lines.length;
  return lines.reduce<MultiLine>((acc, line, i) => {
    line.points.forEach(point => {
      if (acc.has(point.x)) {
        (acc.get(point.x) as (number | null)[])[i] = point.y;
      } else {
        const yValues = generateArray<number>(numberOfLines, 0);
        yValues[i] = point.y;
        acc.set(point.x, yValues);
      }
    });
    return acc;
  }, new Map());
};

const getGraphDataFromMultiLine = (lines: MultiLine): GraphData => {
  return Array.from(lines.keys())
    .sort()
    .map<GraphEntity>(key => [key, ...(lines.get(key) || [])]);
};

export const useMultiGraphData = (data: VaccinationChartEntity[], columns: string[]): GraphData => {
  return useMemo(() => {
    const lines = data
      .map(vaccinationChartEntry => getVaccinationChartLine(vaccinationChartEntry, columns))
      .filter(line => line.points.length > 0);
    const combinedLines = groupLines(lines);

    const graphData = [
      ['Year', ...lines.map(l => l.title)],
      ...getGraphDataFromMultiLine(combinedLines),
    ];

    return graphData;
  }, [data, columns]);
};
