import { ReactComponent as BacteriaSVG } from 'assets/Microbes/Bacteria.svg';
import { ReactComponent as MycobacteriaSVG } from 'assets/Microbes/Mycobacteria.svg';
import { ReactComponent as YeastsSVG } from 'assets/Microbes/Yeasts.svg';
import { ReactComponent as FungusSVG } from 'assets/Search/Fungi.svg';
import { ReactComponent as ParasiteSVG } from 'assets/Search/parasitic.svg';
import { ReactComponent as VirusSVG } from 'assets/Search/Virus.svg';
import { MicrobeButton } from 'Molecules/buttons/MicrobeButton';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { usePushState } from 'services/usePushState.hook';
import styled from 'styled-components/macro';
import { microbeNames } from 'types/az';

const MicrobeCategories = styled.div`
  display: flex;
  margin: 30px 0;
`;

const StyledMicrobeButton = styled(MicrobeButton)`
  margin-right: 15px;
`;

interface Props {
  basePath: string;
}

export const AzMicrobesButtons: FC<Props> = ({ basePath }) => {
  const { push } = usePushState();
  const location = useLocation();

  return (
    <MicrobeCategories>
      <StyledMicrobeButton
        Icon={BacteriaSVG}
        caption={microbeNames.bacteria}
        onClick={() => push(`${basePath}/bacteria`)}
        active={location.pathname.startsWith(`${basePath}/bacteria`)}
      />
      <StyledMicrobeButton
        Icon={FungusSVG}
        caption={microbeNames.fungi}
        onClick={() => push(`${basePath}/fungi`)}
        active={location.pathname.startsWith(`${basePath}/fungi`)}
      />
      <StyledMicrobeButton
        Icon={MycobacteriaSVG}
        caption={microbeNames.mycobacteria}
        onClick={() => push(`${basePath}/mycobacteria`)}
        active={location.pathname.startsWith(`${basePath}/mycobacteria`)}
      />
      <StyledMicrobeButton
        Icon={ParasiteSVG}
        caption={microbeNames.parasites}
        onClick={() => push(`${basePath}/parasites`)}
        active={location.pathname.startsWith(`${basePath}/parasites`)}
      />
      <StyledMicrobeButton
        Icon={VirusSVG}
        caption={microbeNames.viruses}
        onClick={() => push(`${basePath}/viruses`)}
        active={location.pathname.startsWith(`${basePath}/viruses`)}
      />
      <StyledMicrobeButton
        Icon={YeastsSVG}
        caption={microbeNames.yeasts}
        onClick={() => push(`${basePath}/yeasts`)}
        active={location.pathname.startsWith(`${basePath}/yeasts`)}
      />
    </MicrobeCategories>
  );
};
