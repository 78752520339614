import { ExternalLink } from 'Atoms/links/ExternalLink';
import { Loader } from 'Atoms/Loader';
import { ModalH3 } from 'Atoms/modalContents/ModalH3';
import { ModalP } from 'Atoms/modalContents/ModalP';
import jwtDecode from 'jwt-decode';
import { ModalCard } from 'Molecules/modalCard/ModalCard';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { ReactNode, useEffect, useState } from 'react';
import { AuthTokenContext } from 'services/localStorage/AuthToken.provider';
import { useContextAssert } from 'services/useContextAssert.hook';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading, Resource } from 'store/types';
import styled from 'styled-components/macro';
import { ReferenceDetails } from 'types/reference';

interface Props {
  className?: string;
  reference: Resource<ReferenceDetails>;
  index: number;
}

interface DecodedToken {
  exp: number;
  iat: number;
  jti: string;
  token_type: string;
  user: DecodedTokenUser;
}

interface DecodedTokenUser {
  email: string;
  first_name: string;
  id: number;
  institution: string;
  institutionId: string;
  last_name: string;
  paper_size: string;
  product: number;
  ref_holding: string;
  ref_otool: string;
  session_id: string;
  type: string;
  username: string;
}

const renderReferenceSection = (name: string, content: string): ReactNode => (
  <>
    <ModalH3>{name}</ModalH3>
    <ModalP>
      <span dangerouslySetInnerHTML={{ __html: content }}></span>
    </ModalP>
  </>
);

const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`;

export const useHandledReferenceData = (
  reference: Resource<ReferenceDetails>
): {
  title: string;
  subTitle?: ReactNode;
  content: ReactNode;
} => {
  const [token, setToken] = useContextAssert(AuthTokenContext);
  const [userTokenRef, setUserToken] = useState<DecodedTokenUser>();
  assertIsNotStoreError(reference);

  useEffect(() => {
    if (token) {
      try {
        const decoded: DecodedToken = jwtDecode(token);
        setUserToken(decoded.user);
      } catch (error) {
        console.error('Failed to decode token', error);
      }
    }
  }, [token]);

  if (isLoading(reference)) {
    return {
      title: ' ',
      content: (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ),
    };
  }

  const getUrl = () => {
    if (reference.sourceCode === 'p' && userTokenRef) {
      const queryParams = new URLSearchParams();

      if (userTokenRef.ref_otool) {
        queryParams.set('otool', userTokenRef.ref_otool);
      }

      if (userTokenRef.ref_holding) {
        queryParams.set('holding', userTokenRef.ref_holding);
      }

      const queryString = queryParams.toString();
      return queryString ? `${reference.url}?${queryString}` : reference.url;
    }
    return reference.url;
  };

  return {
    title: reference.title ? reference.title : reference.sourceName,
    subTitle: reference.citation ? <DynamicHtml content={reference.citation} /> : <></>,
    content: (
      <>
        {reference.authors && renderReferenceSection('Author(s)', reference.authors)}
        {reference.abstract
          ? renderReferenceSection('Abstract', reference.abstract)
          : reference.sourceDescription
          ? renderReferenceSection('Abstract', reference.sourceDescription)
          : ''}
        {reference.url && (
          <ExternalLink to={getUrl()} openInNewTab>
            Open reference source in new tab
          </ExternalLink>
        )}
      </>
    ),
  };
};

const ReferenceCardBase: React.FC<Props> = ({ className, reference, index }) => {
  const { title, subTitle, content } = useHandledReferenceData(reference);

  return (
    <ModalCard className={className} title={`${index}. ${title}`} subTitle={subTitle}>
      {content}
    </ModalCard>
  );
};

export const ReferenceCard = styled(ReferenceCardBase)``;
