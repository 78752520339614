import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';
import { CountryWithSlug } from 'types/country';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'AvailableGraphCountries/LoadInitiated'>
  | ActionWithPayload<'AvailableGraphCountries/Loaded', CountryWithSlug[]>
  | ActionWithPayload<'AvailableGraphCountries/LoadFailed', StoreError>;

export interface AvailableGraphCountries {
  data: CountryWithSlug[];
}

export type State = Resource<AvailableGraphCountries> | null;

const initialState: State = null;

export const {
  dispatchContext: AvailableGraphCountriesDispatchContext,
  stateContext: AvailableGraphCountriesStateContext,
  provider: AvailableGraphCountriesStoreProvider,
} = storeFactory(reducer, initialState);
