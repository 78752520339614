import { countryCodesISO2, countryCodesISO3 } from 'services/countryCodes';
import { Region } from 'store/regionStore/provider';
import { Country, CountrySelectOption } from 'types/country';
import { SelectOption } from 'types/select';

import { getFlagPath } from './getFlagPath';

export const mapCountriesToSelectOptions = (countries: Country[]): CountrySelectOption[] => {
  return countries.map(c => {
    const flagPath = getFlagPath(c.name, c.id);

    return {
      value: c.id.toString(),
      iso2Code: countryCodesISO2[c.name],
      iso3Code: countryCodesISO3[c.name],
      label: c.name,
      imageUrl: flagPath.light,
      isSynonym: false,
      darkImageUrl: flagPath.dark,
    };
  });
};

export const mapRegionsToSelectOptions = (regions: Region[]): SelectOption[] => {
  return regions.map(c => {

    return {
      value: c.id.toString(),
      label: c.name,
      isSynonym: false,
      source:c.source
    };
  });
};


