import axios from 'axios.cached';
import { CategoryType } from 'types/az';
import { SelectedFingerprintState } from 'types/fingerprint';
import { FungusType, MicrobeType, ParasiteType, VirusType } from 'types/microbeDetails';
import { Category, CompareCategory } from 'types/pdf';

import { DataDTO } from '../common/DTO';

type UrlDTO = {
  url: string;
};

export const getPDFUrl = async (
  category: Category,
  id: string | number,
  email?: string
): Promise<string> => {
  const response = await axios.get<DataDTO<UrlDTO>>(`/api/pdf/${category}/${id}`, {
    params: { emailto: email },
  });
  return response.data.data.url;
};

export const getPatientHandoutPdfUrl = async (
  category: Category,
  id: string | number,
): Promise<string> => {
  const response = await axios.get(`/api/pdf/${category}/${id}/patient-handout`);
  return response.data.data.url;
};

export const getComparePDFUrl = async (
  category: CompareCategory,
  ids: number[],
  email?: string
): Promise<string> => {
  const idsParamName = (category === 'diseases' || category === 'drugs') ? category : category === 'fingerprint' ? 'diseases' : 'codes';
  const url = category === 'fingerprint' ? `/api/pdf/diseases/${category}/compare` : `/api/pdf/${category}/compare`;
  const response = await axios.get<DataDTO<UrlDTO>>(url, {
    params: {
      unique: 'yes',
      [idsParamName]: ids.toString(),
      emailto: email,
    },
  });
  return response.data.data.url;
};

export const getDiagnosePDFUrl = async (params: string): Promise<string> => {
  const response = await axios.get<DataDTO<UrlDTO>>(`/api/pdf/diagnose?${params}`);
  return response.data.data.url;
};

export const getLabIdentifyPDFUrl = async (
  microbe: MicrobeType,
  params: URLSearchParams
): Promise<string> => {
  const response = await axios.get<DataDTO<UrlDTO>>(`/api/pdf/${microbe}/identify?${params}`);
  return response.data.data.url;
};

export const getFingerprintPDFUrl = async (
  selectedFingerprints: SelectedFingerprintState
): Promise<string> => {
  const response = await axios.get<DataDTO<UrlDTO>>('/api/pdf/diseases/fingerprint', {
    params: { ...selectedFingerprints },
  });

  return response.data.data.url;
};

export const getAZListPDFUrl = async (
  category?: CategoryType | MicrobeType | VirusType|ParasiteType|FungusType,
  email?: string
): Promise<string> => {
  const response = await axios.get<DataDTO<UrlDTO>>(
    category ? `/api/pdf/az/${category}` : '/api/pdf/az',
    {
      params: { emailto: email },
    }
  );
  return response.data.data.url;
};

export const getOutbreaksPDFUrl = async (year: string): Promise<string> => {
  const response = await axios.get<DataDTO<UrlDTO>>(`/api/pdf/diseases/outbreaks?year=${year}`);
  return response.data.data.url;
};

export const getDiseaseOutbreaksPDFUrl = async (
  diseaseId: number,
  startYear: string,
  endYear: string
): Promise<string> => {
  const response = await axios.get<DataDTO<UrlDTO>>(
    `/api/pdf/diseases/${diseaseId}/outbreaks?start_year=${startYear}&end_year=${endYear}`
  );
  return response.data.data.url;
};
