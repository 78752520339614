import { getRelatedRegionsAndCountries } from 'apiServices/Country/country';
import { RelatedRegionsAndCountriesDTO } from 'apiServices/Diseases/DTO';
import React, { useEffect, useMemo } from 'react';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, isLoading, Loading, Resource } from 'store/types';

import {
  Action,
  RelatedRegionsAndCountries,
  RelatedRegionsAndCountriesDispatchContext,
  RelatedRegionsAndCountriesStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(RelatedRegionsAndCountriesStateContext);
  if (state === undefined) {
    throw new Error('country state is not initialized');
  }
  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(RelatedRegionsAndCountriesDispatchContext);
  if (dispatch === undefined) {
    throw new Error('country state is not initialized');
  }
  return dispatch;
};

export const useRelatedRegionsAndCountriesResource = (
  id: string
): Resource<RelatedRegionsAndCountries> => {
  const state = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'RelatedRegionsAndCountries/LoadInitiated' });
    getRelatedRegionsAndCountries(id)
      .then(data => dispatch({ type: 'RelatedRegionsAndCountries/Loaded', payload: data }))
      .catch(err =>
        dispatch({
          type: 'RelatedRegionsAndCountries/LoadFailed',
          payload: newStoreError(err.message, err.code, err),
        })
      );
  }, [id]);

  return state || Loading;
};

export const useRelatedRegionsAndCountriesList = (id: string): RelatedRegionsAndCountriesDTO[] => {
  const countries = useRelatedRegionsAndCountriesResource(id);

  assertIsNotStoreError(countries);

  return useMemo(() => (isLoading(countries) ? [] : countries.data), [countries]);
};
