import { GraphTable } from 'Atoms/table/GraphTable';
import React, { FC } from 'react';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { Chart } from 'types/chart';
import { Column } from 'types/table';
import { formatNumber } from 'utils/formatNumber';

interface Props {
  chartData: Chart;
}

export const CountryNotesGraphTable: FC<Props> = React.memo(({ chartData: chart }) => {
  const columns: Column[] = chart.coordinates.map(c => ({ name: c.x, textAlign: 'right' }));
  const rows = chart.coordinates.map(c => ({
    value: c.y,
    label: formatNumber(parseFloat(c.y)),
    references:
      c.references.length > 0
        ? parseDynamicContent(
            c.references
              .map(ref =>
                ref.reference_number !== ' ' && ref.reference_number
                  ? `{${ref.reference_number}}`
                  : null
              )
              .filter(Boolean)
              .join(' ')
          )
        : [],
  }));
  return <GraphTable row={rows} columns={columns} />;
});
