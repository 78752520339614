import React, { FC, useContext } from 'react';
import { Chart } from 'react-google-charts';
import { useMobile } from 'services/useMobile';
import styled, { ThemeContext } from 'styled-components/macro';

import { Loader } from './Loader';

export type GraphCell = string | number;
export type GraphEntity = GraphCell[];
export type GraphData = GraphEntity[];

interface Props {
  data: GraphData;
  legendPosition?: 'bottom' | 'right';
  yTitle1?: string;
  yTitle2?: string;
  xTitle?: string;
  smooth?: boolean;
  dualAxis?: boolean;
  yMaxValue1?: string;
  yMaxValue2?: string;
  isMobile?: boolean;
}

const ChartWrapper = styled.div`
  display: inline;
  svg text {
    fill: ${props => props.theme.colors.text.main};
  }

  .google-visualization-tooltip text {
    fill: #000000;
  }
`;

const ChartStyled = styled(Chart)`
  text {
    font-family: 'Inter', sans-serif;
  }
`;

export const Graph: FC<Props> = React.memo(
  ({
    data,
    legendPosition = 'bottom',
    xTitle,
    yTitle1,
    yTitle2,
    smooth,
    dualAxis,
    yMaxValue1,
    yMaxValue2,
    isMobile: isMobileOverride,
  }) => {
    const theme = useContext(ThemeContext);
    const isMobileActual = useMobile('m');
    const isMobile = isMobileOverride === undefined ? isMobileActual : isMobileOverride;
    const yAxisTitle1 = isMobile ? '' : yTitle1 || '';
    const yAxisTitle2 = isMobile ? '' : yTitle2 || '';
    const xAxisTitle = isMobile ? '' : xTitle || '';

    return (
      <ChartWrapper>
        <ChartStyled
          width="100%"
          height="100%"
          chartType="LineChart"
          loader={<Loader />}
          data={data}
          options={{
            chartArea: {
              left: isMobile ? '12%' : 120,
              top: 20,
              bottom: 100,
              right: isMobile ? '10%' : 150,
              width: '100%',
              height: '100%',
            },
            fontName: 'Inter',
            fontSize: 16,
            lineWidth: 5,
            legend: { position: isMobile ? 'bottom' : legendPosition },
            series: dualAxis
              ? {
                  0: { targetAxisIndex: 0 },
                  1: { targetAxisIndex: 1 },
                }
              : {},
            hAxis: {
              title: xAxisTitle,
              slantedText: true,
            },
            vAxes: {
              0: {
                title: yAxisTitle1,
                gridlines: { count: 7 },
                format: data.some(d => {
                  const cell: GraphCell = d[1];
                  const val: number = typeof cell === 'string' ? 0 : cell || 0;
                  return val >= 1000000;
                })
                  ? 'short'
                  : '#,###',
                viewWindow: {
                  max: yMaxValue1,
                  min: 0,
                },
              },
              1: {
                title: yAxisTitle2,
                gridlines: { count: 0 },
                minorGridlines: { count: 0 },
                format: data.some(d => {
                  const cell: GraphCell = d[2];
                  const val: number = typeof cell === 'string' ? 0 : cell || 0;
                  return val >= 1000000;
                })
                  ? 'short'
                  : '#,###',
                viewWindow: {
                  max: yMaxValue2,
                  min: 0,
                },
              },
            },
            animation: {
              startup: true,
              easing: 'out',
              duration: 500,
            },
            curveType: smooth ? 'function' : '',
            backgroundColor: theme.colors.background.modalCardBody,
            tooltip: { trigger: 'selection' },
          }}
        />
      </ChartWrapper>
    );
  }
);
