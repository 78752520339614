import { Dictionary } from './common';
import { FungusType, ParasiteType, VirusType } from './microbeDetails';

export type Entry = { id: number | string; name: string };

export interface GroupedData {
  data: SectionsDictionary;
  total: number;
}
export type SectionsDictionary = Dictionary<Entry[]>;

export const categories = ['diseases', 'drugs', 'vaccines', 'countries', 'microbes'] as const;
export type CategoryType = typeof categories[number];

export const microbes = ['bacteria', 'mycobacteria', 'yeasts'] as const;
export const microbeItems = [...microbes, 'viruses','parasites','fungi'] as const;
export type MicrobeType = typeof microbes[number];

export const diseases = ['algal', 'bacterial', 'fungal', 'parasitic', 'prion', 'protoctistal', 'viral', 'unknown-agent'] as const;
export type DiseaseType = typeof diseases[number];
export const diseaseNames: Record<DiseaseType, string> = {
  algal: 'Algal',
  bacterial: 'Bacterial',
  fungal: 'Fungal',
  parasitic: 'Parasitic',
  prion: 'Prion',
  protoctistal: 'Protoctistal',
  viral: 'Viral',
  'unknown-agent': 'Unknown agent',
};

export const microbeNames: Record<MicrobeType | VirusType|ParasiteType|FungusType, string> = {
  bacteria: 'Bacteria',
  mycobacteria: 'Mycobacteria',
  yeasts: 'Yeasts and Algae',
  viruses: 'Viruses',
  parasites: 'Parasites',
  fungi: 'Fungi'
};

export const isMicrobe = (value: string): value is MicrobeType =>
  value === 'bacteria' || value === 'mycobacteria' || value === 'yeasts';
