import { ReactComponent as CountriesSvg } from 'assets/ExploreFocus/Countries.svg';
import { ReactComponent as DiseasesSvg } from 'assets/ExploreFocus/Diseases.svg';
import { ReactComponent as DrugsSvg } from 'assets/ExploreFocus/Drugs.svg';
import { ReactComponent as MicrobesSvg } from 'assets/ExploreFocus/Microbes.svg';
import { ReactComponent as VaccinesSvg } from 'assets/ExploreFocus/Vaccines.svg';
import { isNull } from 'lodash';
import {
  AZCountries,
  AZDiseasesWrapper,
  AZDrugs,
  AZMicrobes,
  AZVaccines,
  Props,
} from 'Organisms/az/AZCategories';
import { FC } from 'react';
import { isLoading, LoadingType } from 'types/common';
import { AZTabSection } from 'types/tab';
import { useContents } from 'Updates/store/contentsStore/hooks';
import { formatNumber, parseNumberWithCommas } from 'utils/formatNumber';

export interface AZSectionType extends AZTabSection {
  Component: FC<Props>;
}

export const useSections = (): AZSectionType[] | LoadingType => {

  const content = useContents();

  if (isLoading(content) || isNull(content)) {
    return 'Loading';
  }
  const {
    disease_count,
    drug_count,
    vaccine_count,
    bacteria_count,
    fungus_count,
    mycobacteria_count,
    parasite_count,
    virus_count,
    yeast_count,
    country_count,
  } = content;

  return [
    {
      name: 'Diseases',
      slug: 'diseases',
      Component: AZDiseasesWrapper,
      count: formatNumber(parseNumberWithCommas(disease_count.count)),
      icon: DiseasesSvg,
    },
    {
      name: 'Drugs',
      slug: 'drugs',
      Component: AZDrugs,
      count: formatNumber(parseNumberWithCommas(drug_count.count)),
      icon: DrugsSvg,
    },
    {
      name: 'Vaccines',
      slug: 'vaccines',
      Component: AZVaccines,
      count: formatNumber(parseNumberWithCommas(vaccine_count.count)),
      icon: VaccinesSvg,
    },
    {
      name: 'Pathogens',
      slug: 'pathogens',
      Component: AZMicrobes,
      count: formatNumber(
        parseNumberWithCommas(bacteria_count.count) +
        parseNumberWithCommas(fungus_count.count) +
        parseNumberWithCommas(mycobacteria_count.count) +
        parseNumberWithCommas(parasite_count.count) +
        parseNumberWithCommas(virus_count.count) +
        parseNumberWithCommas(yeast_count.count)
      ),
      icon: MicrobesSvg,
    },
    {
      name: 'Countries',
      slug: 'countries',
      Component: AZCountries,
      count: formatNumber(parseNumberWithCommas(country_count.count)),
      icon: CountriesSvg,
    },
  ];
};
