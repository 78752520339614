import { AssociatedDiseaseWithReference } from 'apiServices/Diseases/DTO';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { OrderedList, OrderedListItem } from 'Atoms/OrderedList';
import { P } from 'Atoms/text';
import { StyledLink } from 'Diagnose/molecules/diagnosisTable/DiagnosisTableDiseaseCell';
import { DrugSpectrumModal } from 'Molecules/drug/DrugSpectrumModal';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { DynamicContent } from 'types/dynamicContent';
import { DrugSusceptibility, MicrobeVaccine } from 'types/microbeDetails';
import { getColumnCount, getWidth } from 'utils/getColumnWidthAndCount';

import { Section, StyledList } from './DiseaseSummarySection';

const Wrapper = styled.div``;

const Category = styled.div`
  margin: 20px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledP = styled(P)`
  margin-bottom: 10px;
`;

export const StyledButton = styled(InvisibleButton)`
  color: ${props => props.theme.colors.text.darkLink};
  font: ${props => props.theme.fonts.normalText};
  cursor: pointer;
`;

export const ReferenceWithLink = styled.div`
  display: flex;
`;

interface Props {
  etymology?: DynamicContent;
  notes: DynamicContent;
  ecology: DynamicContent | null;
  synonyms?: string[];
  susceptibility?: DrugSusceptibility[];
  vaccines?: MicrobeVaccine[];
  associated_diseases?: AssociatedDiseaseWithReference[];
}

export const MicrobeSummarySection: FC<Props> = ({
  etymology,
  notes,
  ecology,
  synonyms,
  susceptibility,
  vaccines,
  associated_diseases,
}) => {
  const [isSpectrumOpen, setSpectrumOpen] = useState<boolean>(false);
  const noteTexts = notes
    .map(item => (item.type === 'text' && item.value !== 'undefined' ? item.value : ''))
    .join();

  return (
    <Wrapper>
      {notes.length > 0 && noteTexts.trim().length > 0 && (
        <Category>
          <StyledP weight="700">Notes</StyledP>
          <DynamicHtml content={notes} />
        </Category>
      )}
      {ecology && ecology.length > 0 && (
        <Category>
          <StyledP weight="700">Ecology</StyledP>
          <DynamicHtml content={ecology} />
        </Category>
      )}
      {susceptibility && susceptibility.length > 0 && (
        <Category>
          <StyledP weight="700">Drug Susceptibility</StyledP>
          {susceptibility.map(x => (
            <StyledButton key={x.code} onClick={() => setSpectrumOpen(true)}>
              {x.pathogen}
            </StyledButton>
          ))}
          <DrugSpectrumModal
            isOpen={isSpectrumOpen}
            onClose={() => setSpectrumOpen(false)}
            initialSpectrumValues={susceptibility.map(x => ({
              value: x.code.toString(),
              label: x.pathogen,
            }))}
          />
        </Category>
      )}
      {vaccines?.length ? (
        <Category>
          <StyledP weight="700">{vaccines.length > 1 ? 'Vaccines' : 'Vaccine'}</StyledP>
          {vaccines.map(d => (
            <ReferenceWithLink key={d.code}>
              <StyledLink key={d.code} to={`/explore/vaccines/${d.code}`}>
                {d.vaccine}{' '}
              </StyledLink>
              {/* {d.references.length > 0 && <DynamicHtml content={d.references} />}{' '} */}
            </ReferenceWithLink>
          ))}
        </Category>
      ) : null}
      {etymology && etymology.length > 0 && (
        <Category>
          <StyledP weight="700">Etymology</StyledP>
          <DynamicHtml content={etymology} />
        </Category>
      )}
      {associated_diseases && associated_diseases.length > 0 && (
        <Section>
          <StyledP weight="700">
            {associated_diseases.length > 1
              ? `Associated Diseases (${associated_diseases.length})`
              : 'Associated Disease'}
          </StyledP>
          <StyledList
            key={'associated-disease-list'}
            containerWidth={getWidth(associated_diseases.length)}
            columnCount={getColumnCount(associated_diseases.length)}
          >
            {associated_diseases.map(x => (
              <ReferenceWithLink key={x.disease}>
                <StyledLink key={x.disease_code} to={`/explore/diseases/${x.disease_code}`}>
                  {x.disease}
                </StyledLink>
                {x.references && <DynamicHtml content={x.references} />}
              </ReferenceWithLink>
            ))}
          </StyledList>
        </Section>
      )}
      {synonyms && synonyms.length > 0 && (
        <Category>
          <StyledP weight="700">Related terms ({synonyms.length})</StyledP>
          <OrderedList>
            {synonyms.map((s: string) => (
              <OrderedListItem key={s}>{s}</OrderedListItem>
            ))}
          </OrderedList>
        </Category>
      )}
    </Wrapper>
  );
};
