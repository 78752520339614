import { ReactComponent as CountriesIcon } from 'assets/ExploreFocus/Countries.svg';
import { ReactComponent as DiseasesIcon } from 'assets/ExploreFocus/Diseases.svg';
import { ReactComponent as DrugsIcon } from 'assets/ExploreFocus/Drugs.svg';
import { ReactComponent as MicrobesIcon } from 'assets/ExploreFocus/Microbes.svg';
import { ReactComponent as VaccinesIcon } from 'assets/ExploreFocus/Vaccines.svg';
import { Loader } from 'Atoms/Loader';
import { isNull } from 'lodash';
import { ExploreFocusSection } from 'Molecules/exploreFocus/ExploreFocusSectionMobile';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { isLoading } from 'types/common';
import { useContents } from 'Updates/store/contentsStore/hooks';
import { formatNullableNumber, parseNumberWithCommas } from 'utils/formatNumber';

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin: 33px 0 20px 0;
`;

export const AZCategoriesSelectorMobile: FC = () => {
  const content = useContents();

  if (isLoading(content) || isNull(content)) {
    return <Loader />;
  }
  const {
    disease_count,
    drug_count,
    vaccine_count,
    bacteria_count,
    fungus_count,
    mycobacteria_count,
    parasite_count,
    virus_count,
    yeast_count,
    country_count,
  } = content;

  const diseasesCount = parseNumberWithCommas(disease_count.count);
  const drugsCount = parseNumberWithCommas(drug_count.count);
  const vaccinesCount = parseNumberWithCommas(vaccine_count.count);
  const countriescount = parseNumberWithCommas(country_count.count);

  const totalPathogenCount =
    parseNumberWithCommas(bacteria_count.count) +
    parseNumberWithCommas(fungus_count.count) +
    parseNumberWithCommas(mycobacteria_count.count) +
    parseNumberWithCommas(parasite_count.count) +
    parseNumberWithCommas(virus_count.count) +
    parseNumberWithCommas(yeast_count.count);

  return (
    <Categories>
      <ExploreFocusSection
        caption={`Diseases (${diseasesCount})`}
        to="/az/diseases"
        Icon={DiseasesIcon}
      />
      <ExploreFocusSection caption={`Drugs (${drugsCount})`} to="/az/drugs" Icon={DrugsIcon} />
      <ExploreFocusSection
        caption={`Vaccines (${vaccinesCount})`}
        to="/az/vaccines"
        Icon={VaccinesIcon}
      />
      <ExploreFocusSection
        caption={`Pathogens (${formatNullableNumber(totalPathogenCount)})`}
        to="/az/pathogens"
        Icon={MicrobesIcon}
      />
      <ExploreFocusSection
        caption={`Countries (${countriescount})`}
        to="/az/countries"
        Icon={CountriesIcon}
      />
    </Categories>
  );
};
