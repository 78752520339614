import axios from 'axios.cached';
import { CategoryType } from 'types/az';
import { SelectedFingerprintState } from 'types/fingerprint';
import { FungusType, MicrobeType, ParasiteType, VirusType } from 'types/microbeDetails';
import { Category, CompareCategory } from 'types/pdf';

import { DataDTO } from '../common/DTO';

type UrlDTO = {
  url: string;
};

export const sendPDF = async (
  category: Category,
  id: string | number,
  email: string
): Promise<void> => {
  await axios.get<DataDTO<UrlDTO>>(`/api/pdf/${category}/${id}`, {
    params: { emailto: email },
  });
};

export const sendComparePDF = async (
  category: CompareCategory,
  ids: number[],
  email: string
): Promise<void> => {
  const idsParamName = (category === 'diseases' || category === 'drugs') ? category : category === 'fingerprint' ? 'diseases' : 'codes';
  const url = category === 'fingerprint' ? `/api/pdf/diseases/${category}/compare` : `/api/pdf/${category}/compare`;
  await axios.get<DataDTO<UrlDTO>>(url, {
    params: {
      unique: 'yes',
      [idsParamName]: ids.toString(),
      emailto: email,
    },
  });
};

export const sendAZListPDF = async (
  email: string,
  category?: CategoryType | MicrobeType | VirusType|ParasiteType|FungusType
): Promise<void> => {
  await axios.get<DataDTO<UrlDTO>>(category ? `/api/pdf/az/${category}` : '/api/pdf/az', {
    params: { emailto: email },
  });
};

export const sendDiagnosePDF = async (params: string, email: string): Promise<void> => {
  await axios.get<DataDTO<UrlDTO>>(`/api/pdf/diagnose?${params}`, {
    params: {
      emailto: email,
    },
  });
};

export const sendLabPDF = async (
  microbe: MicrobeType,
  params: URLSearchParams,
  email: string
): Promise<void> => {
  await axios.get<DataDTO<UrlDTO>>(`/api/pdf/${microbe}/identify?${params}`, {
    params: { emailto: email },
  });
};

export const sendFingerprintPDF = async (
  selectedFingerprints: SelectedFingerprintState,
  email: string
): Promise<void> => {
  await axios.get<DataDTO<UrlDTO>>('/api/pdf/diseases/fingerprint', {
    params: { ...selectedFingerprints, emailto: email },
  });
};
