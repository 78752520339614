import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'Regions/LoadInitiated'>
  | ActionWithPayload<'Regions/Loaded', Region[]>
  | ActionWithPayload<'Regions/LoadFailed', StoreError>;

export interface Region {
  id: number;
  source: string;
  name: string;
}

export interface Regions {
  data: Region[];
  list: number[];
}

export type State = Resource<Regions> | null;
const initialState: State = null;

export const {
  dispatchContext: RegionsDispatchContext,
  stateContext: RegionsStateContext,
  provider: RegionsStoreProvider,
} = storeFactory(reducer, initialState);
