import { isNotNull } from 'apiServices/common/guards';
import { Parasite } from 'Explore/pages/Microbes/Microbe/Parasite';
import { Dictionary } from 'types/common';

const categories: Dictionary<string> = {
  diseases: '/explore/diseases',
  drugs: '/explore/drugs',
  vaccines: '/explore/vaccines',
  yeasts: '/explore/pathogens/yeasts',
  bacteria: '/explore/pathogens/bacteria',
  mycobacteria: '/explore/pathogens/mycobacteria',
  country: '/explore/countries',
  viruses: '/explore/pathogens/viruses',
  parasites: '/explore/pathogens/parasites',
  fungi: '/explore/pathogens/fungi',
};

const getThirdSection = (category: string, sectionValue: string): string | null => {
  if (category !== 'diseases' || !sectionValue || !sectionValue.match(/^G\d{2,3}/g)) {
    return null;
  }

  if (sectionValue === 'G100') {
    return 'worldwide';
  } else if (sectionValue === 'G90') {
    return 'bioterrorism';
  } else {
    return sectionValue;
  }
};

const getHash = (hash: string): string => {
  if (hash === 'clinical') {
    return 'Clinical findings';
  } else if (hash === 'travel') {
    return 'Country note';
  }

  return hash;
};

export const getSearchResultPath = (id: string): string | null => {
  const sections = id.split('-');

  if (sections.length < 2) {
    return null;
  }

  const category = categories[sections[0]];
  const categoryItemId = sections[1];
  const thirdSection = getThirdSection(sections[0], sections[2]);

  const path = [category, categoryItemId, thirdSection].filter(isNotNull).join('/');

  const hashIndex = thirdSection ? 3 : 2;
  const hash = getHash(sections.slice(hashIndex).join('-'));

  return hash ? `${path}#${hash}` : path;
};
