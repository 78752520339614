import { DataDTO, ImageDTO } from 'apiServices/common/DTO';
import axios from 'axios.cached';
import { getSlug } from 'services/slug/slug.service';
import {
  MicrobeDetails,
  MicrobeDetailsPublic,
  MicrobeFilter,
  MicrobeType,
  ParasiteFungusDetails,
  ParasiteFungusDetailsPublic,
  VirusDetails,
  VirusDetailsPublic,
} from 'types/microbeDetails';
import { SimpleMicrobe, SimpleMicrobeWithSlug } from 'types/simpleMicrobe';

import {
  BacteriaDetailsDTO,
  BacteriaDetailsPublicDTO,
  MycobacteriaDetailsDTO,
  MycobacteriaDetailsPublicDTO,
  ParasiteFungusDetailDTO,
  PhenotypeDTO,
  SimpleBacteriaDTO,
  SimpleFungusDTO,
  SimpleMycobacteriaDTO,
  SimpleParasiteDTO,
  SimpleVirusDTO,
  SimpleYeastDTO,
  VirusDetailDTO,
  YeastsDetailsDTO,
  YeastsDetailsPublicDTO,
} from './DTO';
import {
  mapAllMicrobeDTO,
  mapBacteriaDetailsDTO,
  mapBacteriaDetailsPublicDTO,
  mapBacteriaDTO,
  mapFungiDTO,
  mapMycobacteriaDetailsDTO,
  mapMycobacteriaDetailsPublicDTO,
  mapMycobacteriaDTO,
  mapParasiteDTO,
  mapParasiteFungusDetailsDTO,
  mapVirusDetailsDTO,
  mapVirusDTO,
  mapYeastDetailsDTO,
  mapYeastDetailsPublicDTO,
  mapYeastsDTO,
} from './microbes.mapper';

export const getBacteriaSimple = async (isPublic?: boolean): Promise<SimpleMicrobeWithSlug[]> => {
  const bacteria = await axios.get<SimpleBacteriaDTO>(
    isPublic ? '/api/public/microbiology/bacteria' : '/api/microbiology/bacteria?return=simple'
  );

  return bacteria.data.data.map(d => ({
    id: d.bacteria_code,
    name: d.bacteria,
    type: 'bacteria',
    slug: getSlug(d.bacteria),
  }));
};

export const getMycobacteriaSimple = async (
  isPublic?: boolean
): Promise<SimpleMicrobeWithSlug[]> => {
  const bacteria = await axios.get<SimpleMycobacteriaDTO>(
    isPublic
      ? '/api/public/microbiology/mycobacteria'
      : '/api/microbiology/mycobacteria?return=simple'
  );

  return bacteria.data.data.map(d => ({
    id: d.mycobacteria_code,
    name: d.mycobacteria,
    type: 'mycobacteria',
    slug: getSlug(d.mycobacteria),
  }));
};

export const getYeastsSimple = async (isPublic?: boolean): Promise<SimpleMicrobeWithSlug[]> => {
  const bacteria = await axios.get<SimpleYeastDTO>(
    isPublic ? '/api/public/microbiology/yeasts' : '/api/microbiology/yeasts?return=simple'
  );

  return bacteria.data.data.map(d => ({
    id: d.yeast_code,
    name: d.yeast,
    type: 'yeasts',
    slug: getSlug(d.yeast),
  }));
};

export const getVirusSimple = async (isPublic?: boolean): Promise<SimpleMicrobeWithSlug[]> => {
  const virus = await axios.get<SimpleVirusDTO>(
    isPublic ? '/api/public/microbiology/virus' : '/api/microbiology/virus?return=simple'
  );

  return virus.data.data.map(d => ({
    id: d.virus_code,
    name: d.virus,
    type: 'viruses',
    slug: getSlug(d.virus)
  }));
};
export const getParasiteSimple = async (isPublic?: boolean): Promise<SimpleMicrobeWithSlug[]> => {
  const parasite = await axios.get<SimpleParasiteDTO>(
    isPublic ? '/api/public/microbiology/parasites' : '/api/microbiology/parasites?return=simple'
  );

  return parasite.data.data.map(d => ({
    id: d.parasite_code,
    name: d.parasite,
    type: 'parasites',
    slug: getSlug(d.parasite),
  }));
};
export const getFungiSimple = async (isPublic?: boolean): Promise<SimpleMicrobeWithSlug[]> => {
  const fungi = await axios.get<SimpleFungusDTO>(
    isPublic ? '/api/public/microbiology/fungi' : '/api/microbiology/fungi?return=simple'
  );

  return fungi.data.data.map(d => ({
    id: d.fungus_code,
    name: d.fungus,
    type: 'fungi',
    slug: getSlug(d.fungus),
  }));
};

export const getMicrobeDetails = async (
  code: number,
  type: MicrobeType
): Promise<MicrobeDetails> => {
  if (type === 'bacteria') {
    const [bacteria, phenotype, images] = await Promise.all([
      axios.get<DataDTO<BacteriaDetailsDTO>>(`/api/microbiology/bacteria/${code}/general`),
      axios.get<DataDTO<PhenotypeDTO[]>>(`/api/microbiology/bacteria/${code}/phenotype`),
      axios.get<DataDTO<ImageDTO[]>>(`/api/microbiology/bacteria/${code}/images`),
    ]);
    
    const maxTimeStamp = Math.max(bacteria.data.timestamp ?? 0, phenotype.data.timestamp ?? 0,images.data.timestamp ?? 0);

    return mapBacteriaDetailsDTO(bacteria.data.data, phenotype.data.data, images.data.data,maxTimeStamp);
  }
  if (type === 'mycobacteria') {
    const [mycobacteria, phenotype, images] = await Promise.all([
      axios.get<DataDTO<MycobacteriaDetailsDTO>>(`/api/microbiology/mycobacteria/${code}/general`),
      axios.get<DataDTO<PhenotypeDTO[]>>(`/api/microbiology/mycobacteria/${code}/phenotype`),
      axios.get<DataDTO<ImageDTO[]>>(`/api/microbiology/mycobacteria/${code}/images`),
    ]);

    const maxTimeStamp = Math.max(mycobacteria.data.timestamp ?? 0, phenotype.data.timestamp ?? 0,images.data.timestamp ?? 0);

    return mapMycobacteriaDetailsDTO(mycobacteria.data.data, phenotype.data.data, images.data.data,maxTimeStamp);
  }
  // TODO should be handled if it is not yeast and return 404
  const [yeast, phenotype, images] = await Promise.all([
    axios.get<DataDTO<YeastsDetailsDTO>>(`/api/microbiology/yeasts/${code}/general`),
    axios.get<DataDTO<PhenotypeDTO[]>>(`/api/microbiology/yeasts/${code}/phenotype`),
    axios.get<DataDTO<ImageDTO[]>>(`/api/microbiology/yeasts/${code}/images`),
  ]);

  const maxTimeStamp = Math.max(yeast.data.timestamp ?? 0, phenotype.data.timestamp ?? 0,images.data.timestamp ?? 0);

  return mapYeastDetailsDTO(yeast.data.data, phenotype.data.data, images.data.data,maxTimeStamp);
};

export const getMicrobeDetailsPublic = async (
  code: number,
  type: MicrobeType
): Promise<MicrobeDetailsPublic> => {
  if (type === 'bacteria') {
    const bacteria = await axios.get<DataDTO<BacteriaDetailsPublicDTO>>(
      `/api/public/microbiology/bacteria/${code}`
    );

    return mapBacteriaDetailsPublicDTO(bacteria.data.data);
  } else if (type === 'mycobacteria') {
    const mycobacteria = await axios.get<DataDTO<MycobacteriaDetailsPublicDTO>>(
      `/api/public/microbiology/mycobacteria/${code}`
    );

    return mapMycobacteriaDetailsPublicDTO(mycobacteria.data.data);
  }

  const yeast = await axios.get<DataDTO<YeastsDetailsPublicDTO>>(
    `/api/public/microbiology/yeasts/${code}`
  );

  return mapYeastDetailsPublicDTO(yeast.data.data);
};

export const getVirusDetails = async (code: number): Promise<VirusDetails> => {
  const virus = await axios.get<DataDTO<VirusDetailDTO>>(
    `/api/microbiology/virus/${code}/general`
  );
  const maxTimeStamp = virus.data.timestamp??0
  return mapVirusDetailsDTO(virus.data.data,maxTimeStamp);
};

export const getParasiteFungusDetails = async (code: number,type:'parasites'|'fungi'): Promise<ParasiteFungusDetails> => {
  const response = await axios.get<DataDTO<ParasiteFungusDetailDTO>>(
    `/api/microbiology/${type}/${code}/general`
  );
  const maxTimeStamp = response.data.timestamp??0
  return mapParasiteFungusDetailsDTO(response.data.data,maxTimeStamp,type);
};

export const getVirusDetailsPublic = async (code: number): Promise<VirusDetailsPublic> => {
  const virus = await axios.get<DataDTO<VirusDetailDTO>>(
    `/api/public/microbiology/virus/${code}`
  );
  const maxTimeStamp = virus.data.timestamp??0
  return mapVirusDetailsDTO(virus.data.data,maxTimeStamp);
};
export const getParasiteFungusDetailsPublic = async (code: number,type:'parasites'|'fungi'): Promise<ParasiteFungusDetailsPublic> => {
  const parasite = await axios.get<DataDTO<ParasiteFungusDetailDTO>>(
    `/api/public/microbiology/${type}/${code}`
  );
  const maxTimeStamp = parasite.data.timestamp??0
  return mapParasiteFungusDetailsDTO(parasite.data.data,maxTimeStamp,type);
};

export const getMicrobeSynonyms = async (
  type: MicrobeFilter,
  search: string,
  limit = 50
): Promise<SimpleMicrobe[]> => {
  const bacteriaUrl = `/api/microbiology/bacteria/synonyms?search=${search}&limit=${limit}`;
  const mycobacteriaUrl = `/api/microbiology/mycobacteria/synonyms?search=${search}&limit=${limit}`;
  const yeastsUrl = `/api/microbiology/yeasts/synonyms?search=${search}&limit=${limit}`;
  const virusUrl = `/api/microbiology/virus/synonyms?search=${search}&limit=${limit}`;
  const parasiteUrl = `/api/microbiology/parasites/synonyms?search=${search}&limit=${limit}`;
  const fungiUrl = `/api/microbiology/fungi/synonyms?search=${search}&limit=${limit}`;

  switch (type) {
    case 'bacteria': {
      const response = await axios.get<SimpleBacteriaDTO>(bacteriaUrl);
      return mapBacteriaDTO(response.data);
    }
    case 'mycobacteria': {
      const response = await axios.get<SimpleMycobacteriaDTO>(mycobacteriaUrl);
      return mapMycobacteriaDTO(response.data);
    }
    case 'yeasts': {
      const response = await axios.get<SimpleYeastDTO>(yeastsUrl);
      return mapYeastsDTO(response.data);
    }
    case 'viruses': {
      const response = await axios.get<SimpleVirusDTO>(virusUrl);
      return mapVirusDTO(response.data);
    }
    case 'parasites': {
      const response = await axios.get<SimpleParasiteDTO>(parasiteUrl);
      return mapParasiteDTO(response.data);
    }
    case 'fungi': {
      const response = await axios.get<SimpleFungusDTO>(fungiUrl);
      return mapFungiDTO(response.data);
    }
    default: {
      const [bacteriaResponse, mycobacteriaResponse, yeastsResponse, virusResponse,parasiteResponse,fungiResponse] = await Promise.all([
        axios.get<SimpleBacteriaDTO>(bacteriaUrl),
        axios.get<SimpleMycobacteriaDTO>(mycobacteriaUrl),
        axios.get<SimpleYeastDTO>(yeastsUrl),
        axios.get<SimpleVirusDTO>(virusUrl),
        axios.get<SimpleParasiteDTO>(parasiteUrl),
        axios.get<SimpleFungusDTO>(fungiUrl)
      ]);

      return mapAllMicrobeDTO(
        bacteriaResponse.data,
        mycobacteriaResponse.data,
        yeastsResponse.data,
        virusResponse.data,
        parasiteResponse.data,
        fungiResponse.data
      );
    }
  }
};

export const getMicrobeSynonymsPublic = async (
  type: MicrobeFilter,
  search: string,
  limit = 50
): Promise<SimpleMicrobe[]> => {
  const bacteriaUrl = `/api/public/microbiology/bacteria/synonyms?search=${search}&limit=${limit}`;
  const mycobacteriaUrl = `/api/public/microbiology/mycobacteria/synonyms?search=${search}&limit=${limit}`;
  const yeastsUrl = `/api/public/microbiology/yeasts/synonyms?search=${search}&limit=${limit}`;
  const virusUrl = `/api/public/microbiology/virus/synonyms?search=${search}&limit=${limit}`;
  const parasiteUrl = `/api/microbiology/parasites/synonyms?search=${search}&limit=${limit}`;
  const fungiUrl = `/api/microbiology/fungi/synonyms?search=${search}&limit=${limit}`;

  switch (type) {
    case 'bacteria': {
      const response = await axios.get<SimpleBacteriaDTO>(bacteriaUrl);
      return mapBacteriaDTO(response.data);
    }
    case 'mycobacteria': {
      const response = await axios.get<SimpleMycobacteriaDTO>(mycobacteriaUrl);
      return mapMycobacteriaDTO(response.data);
    }
    case 'yeasts': {
      const response = await axios.get<SimpleYeastDTO>(yeastsUrl);
      return mapYeastsDTO(response.data);
    }
    case 'viruses': {
      const response = await axios.get<SimpleVirusDTO>(virusUrl);
      return mapVirusDTO(response.data);
    }
    case 'parasites': {
      const response = await axios.get<SimpleParasiteDTO>(parasiteUrl);
      return mapParasiteDTO(response.data);
    }
    case 'fungi': {
      const response = await axios.get<SimpleFungusDTO>(fungiUrl);
      return mapFungiDTO(response.data);
    }
    default: {
      const [bacteriaResponse, mycobacteriaResponse, yeastsResponse, virusResponse,parasiteResponse,fungiResponse] = await Promise.all([
        axios.get<SimpleBacteriaDTO>(bacteriaUrl),
        axios.get<SimpleMycobacteriaDTO>(mycobacteriaUrl),
        axios.get<SimpleYeastDTO>(yeastsUrl),
        axios.get<SimpleVirusDTO>(virusUrl),
        axios.get<SimpleParasiteDTO>(parasiteUrl),
        axios.get<SimpleFungusDTO>(fungiUrl)
      ]);

      return mapAllMicrobeDTO(
        bacteriaResponse.data,
        mycobacteriaResponse.data,
        yeastsResponse.data,
        virusResponse.data,
        parasiteResponse.data,
        fungiResponse.data

      );
    }
  }
};
