import { RelatedRegionsAndCountriesDTO, SimpleCountry } from 'apiServices/Diseases/DTO';
import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as GenericAction, ActionWithPayload, Resource } from 'store/types';

import { reducer } from './reducer';

export type Action =
  | GenericAction<'RelatedRegionsAndCountries/LoadInitiated'>
  | ActionWithPayload<'RelatedRegionsAndCountries/Loaded', RelatedRegionsAndCountriesDTO[]>
  | ActionWithPayload<'RelatedRegionsAndCountries/LoadFailed', StoreError>;

export interface RelatedRegionsAndCountries {
  data: RelatedRegionsAndCountriesDTO[];
}

export type State = Resource<RelatedRegionsAndCountries> | null;

const initialState: State = null;

export const {
  dispatchContext: RelatedRegionsAndCountriesDispatchContext,
  stateContext: RelatedRegionsAndCountriesStateContext,
  provider: RelatedRegionsAndCountriesStoreProvider,
} = storeFactory(reducer, initialState);
