import {
  getVaccinesContraindications,
  getVaccinesDetailed,
  getVaccinesSimple,
  getVaccinesToxicity,
} from 'apiServices/Vaccines/vaccines';
import { getVaccinesFiltered } from 'apiServices/Vaccines/vaccines';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import { assertIsNotStoreError, newStoreError } from 'store/storeError';
import { Dispatch, isLoading } from 'store/types';
import {
  Action,
  DetailedVaccine,
  State,
  Vaccines,
  VaccinesDispatchContext,
  VaccinesStateContext,
  VaccineWithSlug,
} from 'store/vaccinesStore/provider';
import { GroupedData } from 'types/az';
import { FilterType } from 'types/vaccine';
import { groupDataAlphabetically } from 'utils/getGroupedData';

export const useState = (): State => {
  const state = useContext(VaccinesStateContext);
  if (state === undefined) {
    throw new Error('vaccine state is not initialized');
  }

  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = useContext(VaccinesDispatchContext);
  if (dispatch === undefined) {
    throw new Error('vaccine state is not initialized');
  }

  return dispatch;
};

export const useVaccinesResource = (loadDetailed = false): Vaccines => {
  const state = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state.vaccines) {
      dispatch({ type: 'Vaccines/LoadInitiated' });
      getVaccinesSimple()
        .then(data => dispatch({ type: 'Vaccines/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Vaccines/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [state.vaccines, dispatch]);

  useEffect(() => {
    if (!state.toxicities) {
      dispatch({ type: 'Vaccines/Toxicity/LoadInitiated' });
      getVaccinesToxicity()
        .then(data => dispatch({ type: 'Vaccines/Toxicity/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Vaccines/Toxicity/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [state.toxicities, dispatch]);

  useEffect(() => {
    if (!state.contraindications) {
      dispatch({ type: 'Vaccines/Contraindications/LoadInitiated' });
      getVaccinesContraindications()
        .then(data => dispatch({ type: 'Vaccines/Contraindications/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Vaccines/Contraindications/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [state.contraindications, dispatch]);

  useEffect(() => {
    if (!state.detailedVaccines && loadDetailed) {
      dispatch({ type: 'VaccinesDetailed/LoadInitiated' });
      getVaccinesDetailed()
        .then(data => dispatch({ type: 'VaccinesDetailed/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'VaccinesDetailed/LoadFailed',
            payload: newStoreError(err.message, err.code, err.response),
          })
        );
    }
  }, [state.detailedVaccines, dispatch, loadDetailed]);

  return state;
};

export const useVaccinesGrouped = (): GroupedData => {
  const list = useVaccinesList();

  return {
    data: groupDataAlphabetically(list),
    total: list.length,
  };
};

export const useVaccinesFiltered = (): [
  State,
  (type: FilterType, value: string) => void,
  number
] => {
  const { detailedVaccines } = useVaccinesResource(true);
  const state = useState();
  const dispatch = useDispatch();

  const filterVaccines = (type: FilterType, value: string): void => {
    dispatch({ type: 'Vaccines/Filter/LoadInitiated' });
    getVaccinesFiltered(type, value)
      .then(data => dispatch({ type: 'Vaccines/Filter/Loaded', payload: data }))
      .catch(err =>
        dispatch({
          type: 'Vaccines/Filter/LoadFailed',
          payload: newStoreError(err.message, err.code, err),
        })
      );
  };

  assertIsNotStoreError(detailedVaccines);

  const total = useMemo(
    () => (isLoading(detailedVaccines) ? 0 : detailedVaccines ? detailedVaccines.length : 0),
    [detailedVaccines]
  );

  return [state, filterVaccines, total];
};

export const useDetailedVaccinesList = (): DetailedVaccine[] => {
  const { detailedVaccines } = useVaccinesResource();

  assertIsNotStoreError(detailedVaccines);

  return useMemo(() => (isLoading(detailedVaccines) || !detailedVaccines ? [] : detailedVaccines), [
    detailedVaccines,
  ]);
};

export const useVaccinesList = (): VaccineWithSlug[] => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  const vaccines = state.vaccines;

  useEffect(() => {
    if (!vaccines) {
      dispatch({ type: 'Vaccines/LoadInitiated' });
      getVaccinesSimple(!isAuthenticated)
        .then(data => dispatch({ type: 'Vaccines/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Vaccines/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }
  }, [vaccines, dispatch, isAuthenticated]);

  assertIsNotStoreError(vaccines);

  return isLoading(vaccines) || !vaccines ? [] : vaccines;
};

export const useGetVaccineList = (): (() => VaccineWithSlug[]) => {
  const state = useState();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAccountController();

  const getVaccineList = useCallback(() => {
    if (!state.vaccines) {
      dispatch({ type: 'Vaccines/LoadInitiated' });
      getVaccinesSimple(!isAuthenticated)
        .then(data => dispatch({ type: 'Vaccines/Loaded', payload: data }))
        .catch(err =>
          dispatch({
            type: 'Vaccines/LoadFailed',
            payload: newStoreError(err.message, err.code, err),
          })
        );
    }

    assertIsNotStoreError(state.vaccines);

    return isLoading(state.vaccines) || !state.vaccines ? [] : state.vaccines;
  }, [state, dispatch, isAuthenticated]);

  return getVaccineList;
};
