export const formatNumber = (num: number): string => {
  return num.toLocaleString('en-US');
};

export const formatNullableNumber = (num: number | null, defaultValue = 'N/A'): string => {
  return typeof num === 'number' ? formatNumber(num) : defaultValue;
};


export const parseNumberWithCommas=(str:string)=> {
  // Remove commas and parse as an integer
  return parseInt(str.replace(/,/g, ''), 10);
}