import { RelatedRegionsAndCountriesDTO } from 'apiServices/Diseases/DTO';
import { Link } from 'Atoms/links/Link';
import { P, Props as PProps } from 'Atoms/text/P';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { groupDataAlphabetically } from 'utils/getGroupedData';

const Wrapper = styled.div`
  column-count: 5;
  column-width: 200px;
  column-gap: 20px;
`;

const Group = styled.div`
  break-inside: avoid-column;
  display: table;
  margin: 20px 0 0 0;
  &:first-child {
    margin-top: 0;
  }
`;

const GroupItem = styled(P).attrs(
  (): Partial<PProps> => ({
    size: 'normal',
  })
)``;

interface Props {
  data: RelatedRegionsAndCountriesDTO[];
}

export const RelatedNameSection: FC<Props> = ({ data }) => {
  const groupedList = groupDataAlphabetically(data, true);

  return (
    <Wrapper>
      {Object.keys(groupedList)
        .sort()
        .map((key: string) => (
          <Group key={key}>
            <P weight="700" size="normal">
              {key}
            </P>
            {groupedList[key].map(item => {
              if (item.isLink) {
                return (
                  <GroupItem key={item.id}>
                    <Link to={`/explore/countries/${item.id}`}>{item.name}</Link>
                  </GroupItem>
                );
              } else if (!item.isLink) {
                return (
                  <GroupItem key={item.id}>
                    <P>{item.name}</P>
                  </GroupItem>
                );
              }
            })}
          </Group>
        ))}
    </Wrapper>
  );
};
