import { AssociatedMicrobesWithReference } from 'apiServices/Diseases/DTO';
import { P } from 'Atoms/text';
import { StyledLink } from 'Diagnose/molecules/diagnosisTable/DiagnosisTableDiseaseCell';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { DynamicContent } from 'types/dynamicContent';
import { getColumnCount, getWidth } from 'utils/getColumnWidthAndCount';

import { Section, StyledList } from './DiseaseSummarySection';
import { ReferenceWithLink } from './MicrobeSummarySection';

const Wrapper = styled.div``;

const Category = styled.div`
  margin: 30px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledP = styled(P)`
  margin-bottom: 5px;
`;

interface Props {
  transmission: DynamicContent | null;
  reservoirs: DynamicContent | null;
  vectors: DynamicContent | null;
  vehicles: DynamicContent | null;
  associated_microbes?: AssociatedMicrobesWithReference[];
}

export const TransmissionSection: FC<Props> = ({
  transmission,
  reservoirs,
  vectors,
  vehicles,
  associated_microbes,
}) => {
  return (
    <Wrapper>
      {transmission && transmission.length > 0 && (
        <Category>
          <DynamicHtml content={transmission} />
        </Category>
      )}
      {associated_microbes && associated_microbes.length > 0 && (
        <Section>
          <StyledP weight="700">
            {associated_microbes.length > 1
              ? `Associated pathogens (${associated_microbes.length})`
              : 'Associated pathogen'}
          </StyledP>
          <StyledList
            key={'associated-microbes-list'}
            containerWidth={getWidth(associated_microbes.length)}
            columnCount={getColumnCount(associated_microbes.length)}
          >
            {associated_microbes.map(x => (
              <ReferenceWithLink key={x.pathogen}>
                <StyledLink
                  key={x.code}
                  to={`/explore/pathogens/${x.type == 'virus' ? 'viruses' : x.type}/${x.code}`}
                >
                  {x.pathogen}
                </StyledLink>

                <DynamicHtml content={x.references} />
              </ReferenceWithLink>
            ))}
          </StyledList>
        </Section>
      )}
      {reservoirs && (
        <Category>
          <StyledP weight="700">Reservoirs</StyledP>
          <DynamicHtml content={reservoirs} />
        </Category>
      )}
      {vectors && (
        <Category>
          <StyledP weight="700">Vectors</StyledP>
          <DynamicHtml content={vectors} />
        </Category>
      )}
      {vehicles && (
        <Category>
          <StyledP weight="700">Vehicles</StyledP>
          <DynamicHtml content={vehicles} />
        </Category>
      )}
    </Wrapper>
  );
};
