import { LogParams } from 'apiServices/Logs/DTO';
import { reportError, reportLog } from 'apiServices/Logs/logs';
import { AxiosError } from 'axios.cached';
import { NotFound } from 'pages/404';
import { NetworkError } from 'pages/NetworkError';
import React from 'react';
import { Component, ErrorInfo, ReactElement, ReactNode } from 'react';
import { AccountStateContext } from 'store/accountStore/provider';
import { isStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import {
  CustomError,
  ErrorCodes,
  ErrorRenderProps,
  Errors,
  isAxiosError,
  isErrorRenderFunction,
} from 'types/errorTypes';
import { prepareErrorReport } from 'utils/generateErrorReport';

interface ErrorBoundaryProps {
  children: ReactNode;
  error: ReactNode | ((props: ErrorRenderProps) => ReactElement);
  hasError?: boolean;
  pathname?: string;
}

interface ErrorBoundaryState {
  errorData?: Errors;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  getLogParams = (error: Errors, errorInfo: ErrorInfo): LogParams => {
    const partialResult = {
      name: error.name,
      message: error.message,
      stackTrace: errorInfo.componentStack,
    };

    if (isAxiosError(error)) {
      return {
        code: error.response?.status.toString(),
        type: 'Error',
        ...partialResult,
      };
    } else if (error instanceof CustomError) {
      return {
        code: error.code?.toString(),
        type: error.type,
        detail: error.code && ErrorCodes[error.code],
        ...partialResult,
      };
    } else {
      return {
        type: 'Error',
        ...partialResult,
      };
    }
  };

  getIsNotFound = (error?: Errors): boolean => {
    return (
      (error &&
        (error as AxiosError).isAxiosError &&
        (error as AxiosError).response?.status === 404) ||
      (error as CustomError).code === ErrorCodes.NotFound
    );
  };

  getIsNetworkError = (error?: Errors): boolean => {
    return (
      error &&
      (error as AxiosError).isAxiosError &&
      (error as AxiosError).request &&
      !(error as AxiosError).response
    );
  };

  static getDerivedStateFromError(error?: Errors): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.

    return { errorData: error || new Error() };
  }

  componentDidCatch(error: Errors, errorInfo: ErrorInfo): void {
    const isAuthenticated =
      !isLoading(this.context.accountSettings) && !isStoreError(this.context.accountSettings);

    if (!this.getIsNotFound(error) && !this.getIsNetworkError(error) && isAuthenticated) {
      const result = this.getLogParams(error, errorInfo);

      reportLog(result);
      reportError(prepareErrorReport(this.context.accountSettings, 'Automatically', error));
    }
  }

  componentDidUpdate(previousProps: ErrorBoundaryProps): void {
    if (previousProps.pathname !== this.props.pathname) {
      this.setState({ errorData: undefined });
    }
  }

  render(): ReactNode {
    const { error } = this.props;
    const { errorData } = this.state;

    if (!errorData) {
      return this.props.children;
    }

    if (this.getIsNotFound(errorData)) {
      return <NotFound />;
    } else if (this.getIsNetworkError(errorData)) {
      return <NetworkError />;
    } else {
      return isErrorRenderFunction(error) ? error({ error: errorData }) : error;
    }
  }
}

ErrorBoundary.contextType = AccountStateContext;
