import { getParasiteFungusDetails, getParasiteFungusDetailsPublic } from 'apiServices/Microbes/microbes';
import React, { useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch, Loading, Resource } from 'store/types';
import { ParasiteFungusDetails, ParasiteFungusDetailsPublic } from 'types/microbeDetails';

import {
  Action,
  FungusDetailsDispatchContext,
  FungusDetailsStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(FungusDetailsStateContext);
  if (state === undefined) {
    throw new Error('Parasite details store is not initialized');
  }

  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(FungusDetailsDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Parasite details store is not initialized');
  }
  return dispatch;
};

export const useFungusDetails = (id: number): Resource<ParasiteFungusDetails> => {
  const state = useState();
  const dispatch = useDispatch();
  const fungusDetails = state.details[id];

  useEffect(() => {
    if (!fungusDetails) {
      dispatch({ type: 'FungusDetails/SingleLoadInitiated', payload: { id } });

      getParasiteFungusDetails(id,'fungi')
        .then(data => dispatch({ type: 'FungusDetails/SingleLoaded', payload: { id, data } }))
        .catch(err =>
          dispatch({
            type: 'FungusDetails/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err.response) },
          })
        );
    }
  }, [dispatch, id, fungusDetails]);

  return fungusDetails || Loading;
};

export const useFungusDetailsPublic = (
  id: number
): Resource<ParasiteFungusDetailsPublic> => {
  const state = useState();
  const dispatch = useDispatch();
  const fungusDetails = state.detailsPublic[id];


  useEffect(() => {
    if (!fungusDetails) {
      dispatch({ type: 'FungusDetails/Public/SingleLoadInitiated', payload: { id } });
      getParasiteFungusDetailsPublic(id,'fungi')
        .then(data =>
          dispatch({ type: 'FungusDetails/Public/SingleLoaded', payload: { id, data } })
        )
        .catch(err => {
          dispatch({
            type: 'FungusDetails/Public/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, id, fungusDetails]);

  return fungusDetails || Loading;
};
