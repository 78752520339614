import { ReactComponent as BackArrowSvg } from 'Updates/assets/BackArrowDark.svg';
import { ReactComponent as bacteria } from 'Updates/assets/content/BacteriaDark.svg';
import { ReactComponent as caseSeries } from 'Updates/assets/content/CaseSeriesDark.svg';
import { ReactComponent as content } from 'Updates/assets/content/ContentDark.svg';
import { ReactComponent as countries } from 'Updates/assets/content/CountriesDark.svg';
import { ReactComponent as countryNotes } from 'Updates/assets/content/CountryNotesDark.svg';
import { ReactComponent as diseases } from 'Updates/assets/content/DiseasesDark.svg';
import { ReactComponent as drugs } from 'Updates/assets/content/DrugsDark.svg';
import { ReactComponent as ebooks } from 'Updates/assets/content/EbooksDark.svg';
import { ReactComponent as fungi } from 'Updates/assets/content/FungiDark.svg';
import { ReactComponent as graphs } from 'Updates/assets/content/GraphsDark.svg';
import { ReactComponent as images } from 'Updates/assets/content/ImagesDark.svg';
import { ReactComponent as mycobacteria } from 'Updates/assets/content/MycobacteriaDark.svg';
import { ReactComponent as outbreak } from 'Updates/assets/content/OutbreakDark.svg';
import { ReactComponent as parasites } from 'Updates/assets/content/Parasite.svg';
import { ReactComponent as references } from 'Updates/assets/content/ReferencesDark.svg';
import { ReactComponent as survey } from 'Updates/assets/content/SurveyDark.svg';
import { ReactComponent as symptoms } from 'Updates/assets/content/SymptomsDark.svg';
import { ReactComponent as tests } from 'Updates/assets/content/TestsDark.svg';
import { ReactComponent as tradeNames } from 'Updates/assets/content/TradeNamesDark.svg';
import { ReactComponent as vaccines } from 'Updates/assets/content/VaccinesDark.svg';
import { ReactComponent as viruses } from 'Updates/assets/content/VirusesDark.svg';
import { ReactComponent as yeasts } from 'Updates/assets/content/YeastsDark.svg';
import { ReactComponent as FolderSvg } from 'Updates/assets/FolderDark.svg';
import { ReactComponent as WorldSvg } from 'Updates/assets/WorldDark.svg';

export const updatesDarkThemeIcons = {
  backArrow: BackArrowSvg,
  folder: FolderSvg,
  world: WorldSvg,
  content: {
    bacteria,
    content,
    countries,
    countryNotes,
    diseases,
    drugs,
    graphs,
    images,
    mycobacteria,
    outbreak,
    references,
    survey,
    symptoms,
    tests,
    tradeNames,
    vaccines,
    yeasts,
    ebooks,
    caseSeries,
    viruses,
    parasites,
    fungi
  },
};
