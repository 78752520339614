import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon, SvgComponent } from 'Atoms/Icon';
import { Span } from 'Atoms/text';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { formatNumber } from 'utils/formatNumber';

const TextContainer = styled.div`
  margin-top: 15px;
`;

const ResultTextContainer = styled.div``;

const Label = styled(Span)`
  white-space: nowrap;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    display: none;
  }
`;

const SearchFacetIconStyled = styled(Icon)`
  height: 50px;
  width: 50px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: 35px;
    width: 31px;
  }
`;

const SearchFacetStyled = styled(InvisibleButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-direction: column;

  cursor: pointer;

  &:hover {
    ${Label}, ${Span} {
      color: ${props => props.theme.colors.button.hover.textColor};
    }

    ${SearchFacetIconStyled} {
      fill: ${props => props.theme.colors.button.hover.textColor};
    }
  }

  &[data-active='true'] {
    ${Label}, ${Span} {
      color: ${props => props.theme.colors.button.hover.textColor};
    }

    ${SearchFacetIconStyled} {
      fill: ${props => props.theme.colors.button.hover.textColor};
    }
  }
`;

interface Props {
  className?: string;
  Icon: SvgComponent;
  count: number;
  onClick: () => void;
  isActive: boolean;
  label: string;
}

export const SearchModule: FC<Props> = ({ className, count, Icon, onClick, isActive, label }) => (
  <SearchFacetStyled
    data-cy={`search-module-${label}`}
    className={className}
    onClick={onClick}
    data-active={isActive}
  >
    <SearchFacetIconStyled svgComponent={Icon} fill="inactive" />
    <TextContainer>
      <Label font="Quicksand" size="big" color="inactive">
        {label}
      </Label>
    </TextContainer>
    <ResultTextContainer>
      <Label font="Quicksand" size="big" color="inactive">
        <Span font="Quicksand" size="big" color="inactive" weight="700">
          {formatNumber(count)}
        </Span>
        {count > 1 ? ' results' : ' result'}
      </Label>
    </ResultTextContainer>
  </SearchFacetStyled>
);
