import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import { PathogenEntity, Reservoir_Vector_Vehicle_WithReference } from 'types/microbeDetails';

import { Category, StyledP, StyledTooltip } from './VirusSummarySection';

interface Props {
  data: Reservoir_Vector_Vehicle_WithReference[];
  title: string;
  tooltipContent: string;
}

export const SummaryCategorySection: FC<Props> = ({ data, title, tooltipContent }) => {
  return (
    <Category>
      <StyledP weight="700">
        {title}
        <StyledTooltip content={tooltipContent} />
      </StyledP>

      {data.length > 1 ? (
        <ul>
          {data.map(item => (
            <li key={item.class}>
              {item.class}
              {!item.isEntityLatin &&
                item.EntityArr.map(
                  (el: PathogenEntity) =>
                    el.references.length > 0 && (
                      <DynamicHtml key={el.entity} content={el.references} />
                    )
                )}

              {item.EntityArr.length > 0 && (
                <ul>
                  {item.EntityArr.map(
                    (el: PathogenEntity, index: number) =>
                      el.entity && (
                        <li key={index}>
                          {el.entity}{' '}
                          {el.entity_latin && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: `(${el.entity_latin})`,
                              }}
                            />
                          )}
                          {el.references.length > 0 && <DynamicHtml content={el.references} />}
                        </li>
                      )
                  )}
                </ul>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <span>
          {data[0].class}
          {!data[0].isEntityLatin && data[0].EntityArr[0]?.references.length > 0 && (
            <DynamicHtml content={data[0].EntityArr[0].references} />
          )}

          {data[0].EntityArr.length > 0 && (
            <ul>
              {data[0].EntityArr.map(
                (el: PathogenEntity) =>
                  el.entity && (
                    <li>
                      {el.entity}{' '}
                      {el.entity_latin && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `(${el.entity_latin})`,
                          }}
                        />
                      )}
                      {el.references.length > 0 && <DynamicHtml content={el.references} />}
                    </li>
                  )
              )}
            </ul>
          )}
        </span>
      )}
    </Category>
  );
};
