import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { AZPageTitle } from 'Molecules/az/AZPageTitle';
import { AZCategoriesSelectorMobile } from 'Organisms/az/AZCategoriesSelectorMobile';
import React, { FC } from 'react';
import { match, Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { isLoading } from 'types/common';

import { useSections } from './AZPageCategories';

const Wrapper = styled.div`
  width: 86%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

interface Props {
  match: match;
}

export const MobileAZ: FC<Props> = ({ match }) => {
  const sections = useSections();

  if (isLoading(sections)) {
    return <Loader />;
  }

  return (
    <MainLayout>
      <Wrapper>
        <AZPageTitle />
        <Wrapper>
          <Switch>
            <Route exact from={match.url} component={AZCategoriesSelectorMobile} />
            {sections.map(section => (
              <Route
                path={`${match.url}/${section.slug}`}
                key={section.slug}
                render={({ match }) => (
                  <section.Component name={section.name} isMobile match={match} />
                )}
              />
            ))}
          </Switch>
        </Wrapper>
      </Wrapper>
    </MainLayout>
  );
};
