import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { FungusType, MicrobeType, ParasiteType, VirusType } from 'types/microbeDetails';
import { SimpleMicrobeWithSlug } from 'types/simpleMicrobe';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<
      'Microbes/LoadInitiated',
      { type: MicrobeType | VirusType | ParasiteType | FungusType }
    >
  | ActionWithPayload<
      'Microbes/Loaded',
      { type: MicrobeType | VirusType | ParasiteType | FungusType; data: SimpleMicrobeWithSlug[] }
    >
  | ActionWithPayload<
      'Microbes/LoadFailed',
      { type: MicrobeType | VirusType | ParasiteType | FungusType; data: StoreError }
    >;

export type State = Record<
  MicrobeType | VirusType | ParasiteType | FungusType,
  Resource<SimpleMicrobeWithSlug[]> | null
>;

const initialState: State = {
  bacteria: null,
  mycobacteria: null,
  yeasts: null,
  viruses: null,
  parasites: null,
  fungi: null,
};

export const {
  dispatchContext: MicrobesDispatchContext,
  stateContext: MicrobesStateContext,
  provider: MicrobesStoreProvider,
} = storeFactory(reducer, initialState);
