import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { ActionWithPayload, Resource } from 'store/types';
import { ParasiteFungusDetails, ParasiteFungusDetailsPublic } from 'types/microbeDetails';

import { reducer } from './reducer';

export type Action =
  | ActionWithPayload<'FungusDetails/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<'FungusDetails/SingleLoaded', { id: number; data: ParasiteFungusDetails }>
  | ActionWithPayload<'FungusDetails/SingleLoadFailed', { id: number; error: StoreError }>
  | ActionWithPayload<'FungusDetails/Public/SingleLoadInitiated', { id: number }>
  | ActionWithPayload<
      'FungusDetails/Public/SingleLoaded',
      { id: number; data: ParasiteFungusDetailsPublic }
    >
  | ActionWithPayload<'FungusDetails/Public/SingleLoadFailed', { id: number; error: StoreError }>;

export type State = {
  details: { [id: number]: Resource<ParasiteFungusDetails> | undefined };
  detailsPublic: { [id: number]: Resource<ParasiteFungusDetailsPublic> | undefined };
};

const initialState: State = {
  details: {},
  detailsPublic: {},
};

export const {
  dispatchContext: FungusDetailsDispatchContext,
  stateContext: FungusDetailsStateContext,
  provider: FungusDetailsStoreProvider,
} = storeFactory(reducer, initialState);
