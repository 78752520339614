import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { H2 } from 'Atoms/text/H';
import { ReactComponent as UpRightArrowSVG } from 'Explore/assets/up-right-arrow.svg';
import { useTrendingData } from 'Explore/store/trendingStore/hooks';
import React, { FC } from 'react';
import { assertIsNotStoreError } from 'store/storeError';
import styled from 'styled-components/macro';
import { isLoading } from 'types/common';

const TrendingWrapper = styled.div`
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.trending-countries {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 220px;
    z-index: 100;
    margin: 0;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      top: 250px;
    }
  }

  &.has-background {
    border-radius: 4px;
    padding: 5px;
    background: ${props => props.theme.colors.countriesTitle};
    width: fit-content;
    margin: 0;
  }
`;

const TrendingText = styled(H2)`
  margin: 0;
  white-space: nowrap;

  &.anchor-text {
    font-weight: bold;
  }
`;

const AnchorWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 30px;
  margin-left: 10px;
`;

const TrendingAnchor = styled(Link)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text.lightLink};
`;

const SVGWrapper = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

interface Props {
  className?: string;
  section:
    | 'diseases'
    | 'drugs'
    | 'vaccines'
    | 'bacteria'
    | 'mycobacteria'
    | 'yeasts'
    | 'countries'
    | 'viruses'
    | 'parasites'
    | 'fungi'
    | 'microbes';
}

export const TrendingNow: FC<Props> = ({ className, section }) => {
  const trendingData = useTrendingData(section);
  assertIsNotStoreError(trendingData);

  const getExploreSection = (s: string): string => {
    if (
      s === 'bacteria' ||
      s === 'mycobacteria' ||
      s === 'yeasts' ||
      s === 'viruses' ||
      s === 'parasites' ||
      s === 'fungi'
    ) {
      return 'pathogens';
    }
    return s;
  };

  const getExploreType = (s: string): string => {
    if (
      s === 'bacteria' ||
      s === 'mycobacteria' ||
      s === 'yeasts' ||
      s === 'viruses' ||
      s === 'parasites' ||
      s === 'fungi'
    ) {
      return `/${s}`;
    }
    return '';
  };

  return (
    <TrendingWrapper className={className}>
      <TrendingText size="smallRegular" font="Inter">
        Trending Now:
      </TrendingText>
      <AnchorWrapper>
        {!trendingData || isLoading(trendingData) ? (
          <Loader />
        ) : trendingData.id ? (
          <TrendingAnchor
            to={`/explore/${getExploreSection(section)}${getExploreType(section)}/${
              trendingData.id
            }`}
          >
            <TrendingText
              size="smallRegular"
              font="Inter"
              color="lightLink"
              className="anchor-text"
            >
              {trendingData.name}
            </TrendingText>
            <SVGWrapper>
              <UpRightArrowSVG width={20} height={20} />
            </SVGWrapper>
          </TrendingAnchor>
        ) : (
          'Not available now'
        )}
      </AnchorWrapper>
    </TrendingWrapper>
  );
};
