import { ReactComponent as CountriesIcon } from 'assets/ExploreFocus/Countries.svg';
import { ReactComponent as DiseasesIcon } from 'assets/ExploreFocus/Diseases.svg';
import { ReactComponent as DrugsIcon } from 'assets/ExploreFocus/Drugs.svg';
import { ReactComponent as MicrobesIcon } from 'assets/ExploreFocus/Microbes.svg';
import { ReactComponent as VaccinesIcon } from 'assets/ExploreFocus/Vaccines.svg';
import { CircleButtonWithIcon } from 'Molecules/buttons/CircleButtonWithIcon';
import React, { FC } from 'react';
import { usePushState } from 'services/usePushState.hook';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`;

const ExploreSectionButton = styled(CircleButtonWithIcon)`
  margin: 0 20px;
`;

export const DesktopExploreFocus: FC = () => {
  const { push } = usePushState();

  return (
    <Wrapper>
      <ExploreSectionButton
        caption="Diseases"
        onClick={() => push('/explore/diseases')}
        icon={DiseasesIcon}
      />
      <ExploreSectionButton
        caption="Drugs"
        onClick={() => push('/explore/drugs')}
        icon={DrugsIcon}
      />
      <ExploreSectionButton
        caption="Vaccines"
        onClick={() => push('/explore/vaccines')}
        icon={VaccinesIcon}
      />
      <ExploreSectionButton
        caption="Pathogens"
        onClick={() => push('/explore/pathogens')}
        icon={MicrobesIcon}
      />
      <ExploreSectionButton
        caption="Countries"
        onClick={() => push('/explore/countries')}
        icon={CountriesIcon}
      />
    </Wrapper>
  );
};
