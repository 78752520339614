import axios from 'axios.cached';
import { getSlug } from 'services/slug/slug.service';
import { Fingerprint, Fingerprints, FingerprintWithSlug } from 'types/fingerprint';
import { SimpleDisease } from 'types/simpleDisease';

import {
  AgentsDTO,
  CountriesDTO,
  ReservoirsDTO,
  SimpleDiseasesDTO,
  VectorsDTO,
  VehiclesDTO,
} from './DTO';

const getAgents = async (): Promise<Fingerprint[]> => {
  const agents = await axios.get<AgentsDTO>('/api/diseases/fingerprint/agents');

  return agents.data.data.map(d => ({
    id: d.agent_code.toString(),
    name: d.agent,
  }));
};

const getVectors = async (): Promise<Fingerprint[]> => {
  const vectors = await axios.get<VectorsDTO>('/api/diseases/fingerprint/vectors');

  return vectors.data.data.map(d => ({
    id: d.vector_code,
    name: d.vector,
  }));
};

const getVehicles = async (): Promise<Fingerprint[]> => {
  const vehicles = await axios.get<VehiclesDTO>('/api/diseases/fingerprint/vehicles');

  return vehicles.data.data.map(d => ({
    id: d.vehicle_code,
    name: d.vehicle,
  }));
};

const getReservoirs = async (): Promise<Fingerprint[]> => {
  const reservoirs = await axios.get<ReservoirsDTO>('/api/diseases/fingerprint/reservoirs');

  return reservoirs.data.data.map(d => ({
    id: d.reservoir_code,
    name: d.reservoir,
  }));
};

export const getCountries = async (isPublic?: boolean): Promise<FingerprintWithSlug[]> => {
  const countries = await axios.get<CountriesDTO>(
    isPublic ? '/api/public/countries' : '/api/diseases/fingerprint/countries'
  );

  return [
    ...countries.data.data
      .filter(d => d.country_code !== 'G90' && d.country_code !== 'G100')
      .map(d => ({
        id: d.country_code,
        name: d.country,
        slug: getSlug(d.country),
        related_countries: [...d.related_countries.map(c=>({id:c.country_code,name:c.country,isLink:true}))]
      })),
  ].sort((a, b) => a.name.localeCompare(b.name));
};

export const getAllFingerprints = async (): Promise<Fingerprints> => {
  const [agents, vectors, vehicles, reservoirs, countries] = await Promise.all([
    getAgents(),
    getVectors(),
    getVehicles(),
    getReservoirs(),
    getCountries(),
  ]);

  return { agents, vectors, vehicles, reservoirs, countries };
};

export const getDiseasesByFingerprint = async (
  agent: string | null,
  vector: string | null,
  vehicle: string | null,
  reservoir: string | null,
  country: string | null
): Promise<SimpleDisease[]> => {
  const params = {
    agent,
    vector,
    vehicle,
    reservoir,
    country,
  };
  const diseases = await axios.get<SimpleDiseasesDTO>('/api/diseases/filter?return=simple', {
    params,
  });

  return diseases.data.data.map(d => ({
    id: d.disease_code,
    name: d.disease,
  }));
};
