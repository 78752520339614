import { getParasiteFungusDetails, getParasiteFungusDetailsPublic } from 'apiServices/Microbes/microbes';
import React, { useEffect } from 'react';
import { newStoreError } from 'store/storeError';
import { Dispatch, Loading, Resource } from 'store/types';
import { ParasiteFungusDetails, ParasiteFungusDetailsPublic } from 'types/microbeDetails';

import {
  Action,
  ParasiteDetailsDispatchContext,
  ParasiteDetailsStateContext,
  State,
} from './provider';

export const useState = (): State => {
  const state = React.useContext(ParasiteDetailsStateContext);
  if (state === undefined) {
    throw new Error('Parasite details store is not initialized');
  }

  return state;
};

export const useDispatch = (): Dispatch<Action> => {
  const dispatch = React.useContext(ParasiteDetailsDispatchContext);
  if (dispatch === undefined) {
    throw new Error('Parasite details store is not initialized');
  }
  return dispatch;
};

export const useParasiteDetails = (id: number): Resource<ParasiteFungusDetails> => {
  const state = useState();
  const dispatch = useDispatch();
  const parasiteDetails = state.details[id];

  useEffect(() => {
    if (!parasiteDetails) {
      dispatch({ type: 'ParasiteDetails/SingleLoadInitiated', payload: { id } });

      getParasiteFungusDetails(id,'parasites')
        .then(data => dispatch({ type: 'ParasiteDetails/SingleLoaded', payload: { id, data } }))
        .catch(err =>
          dispatch({
            type: 'ParasiteDetails/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          })
        );
    }
  }, [dispatch, id, parasiteDetails]);

  return parasiteDetails || Loading;
};

export const useParasiteDetailsPublic = (
  id: number
): Resource<ParasiteFungusDetailsPublic> => {
  const state = useState();
  const dispatch = useDispatch();
  const parasiteDetails = state.detailsPublic[id];


  useEffect(() => {
    if (!parasiteDetails) {
      dispatch({ type: 'ParasiteDetails/Public/SingleLoadInitiated', payload: { id } });
      getParasiteFungusDetailsPublic(id,'parasites')
        .then(data =>
          dispatch({ type: 'ParasiteDetails/Public/SingleLoaded', payload: { id, data } })
        )
        .catch(err => {
          dispatch({
            type: 'ParasiteDetails/Public/SingleLoadFailed',
            payload: { id, error: newStoreError(err.message, err.code, err) },
          });
        });
    }
  }, [dispatch, id, parasiteDetails]);

  return parasiteDetails || Loading;
};
